import styled from 'styled-components'; //macro

import theme from 'app/theme';

export const LinkWrapper = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'fit-content')};

  & a {
    text-decoration: none;
    margin: 0 0.2rem;
  }

  & a .material-icons {
    transition: 250ms;
  }

  & a:hover .material-icons {
    color: ${theme.color.black};
  }
`;
