import styled, { css } from 'styled-components'; //macro

import IconButton from 'core/components/IconButton';

export const FlagButton = styled(IconButton)`
  ${({ onClick }) =>
    !onClick &&
    css`
      pointer-events: none;
    `}
`;
