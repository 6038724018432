import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';
import { Grid } from 'core/components/Grid';
import Modal from 'core/components/Modal';
import { StyledTabBar as TabBar } from 'core/components/StyledTabBar/styled';

export const StyledModal = styled(Modal)`
  padding: 2rem;

  &[data-reach-dialog-overlay] > div[role='dialog'] {
    max-width: 100%;
    width: 100%;
    height: 100%;

    & > div:nth-of-type(1) {
      padding: 0 1.8rem;
    }

    & > div:nth-of-type(2) {
      padding: 0.6rem 1.2rem;
    }
  }
`;

export const NoPaddingGrid = styled(Grid)`
  padding: 0 !important;
`;

export const FilterTrigger = styled.div<{ disabled: boolean; opened: boolean }>`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${(p) => (p.opened ? theme.color.primary : theme.color.secondary700)};
  cursor: pointer;
  white-space: nowrap;

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  .material-icons {
    font-size: 1.125rem;
  }
`;

export const FilterText = styled.span<{ active?: boolean }>`
  ${(props) =>
    props.active &&
    css`
      color: var(--mdc-theme-primary);
    `}
`;

export const StyledTabBar = styled(TabBar)`
  margin-bottom: 1.35rem;

  & .mdc-tab {
    text-transform: uppercase;
    font-size: 0.938rem !important;
    letter-spacing: 0;
    font-weight: 500;
    line-height: 1rem;
    height: 40px;
  }

  border-bottom: 1px solid ${theme.color.gray100};
`;
