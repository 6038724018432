import { Saga } from '@redux-saga/types';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { DeepPartial, Reducer } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { AppState } from 'app/store';
import createSentryMiddleware from 'core/store/sentryMiddleware';

import { State } from './prepareStore';

const prepareStore = (
  rootReducer: Reducer,
  sagas: Saga,
  history: History,
  preloadedState?: DeepPartial<State>
) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false, serializableCheck: false })
        .concat(routerMiddleware(history) as any)
        .concat(sagaMiddleware)
        .concat(
          createSentryMiddleware<AppState, any>(Sentry, {
            getUserContext: (state) =>
              state.core.user
                ? {
                    id: state.core.user.id.toString(),
                    email: state.core.user.email,
                    client: {
                      id: state.core.user.client.id,
                      name: state.core.user.client.fullName,
                    },
                    moreData: 'Check additional data "store" where you can see all data',
                  }
                : { username: 'Unauthenticated' },
          })
        ),
    reducer: rootReducer,
    preloadedState,
  });

  sagaMiddleware.run(sagas);

  return store;
};

export default prepareStore;
