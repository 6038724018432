import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleOption } from 'core/components/DropDown';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import { defaultComparator } from 'core/components/FormDropDown/sortOptionsHelper';
import usePermission from 'core/hooks/usePermission';
import useResource from 'core/hooks/useResource';
import Sport from 'lists/models/Sport';
import SportDiscipline from 'lists/models/SportDiscipline';
import Athlete from 'personnel/models/Athlete';

/**
 * Provides Sport and Sport Discipline dropdown options reducer prioritizing athlete ones.
 */
const useSportsPrioritizedByAthleteReducer = (
  athlete: Athlete | number | null,
  fieldsNamePrefix?: string
) => {
  const hasGetPermission = usePermission('athletes:get');
  const { t } = useTranslation();
  const getName = useCallback(
    (n: string) => (fieldsNamePrefix ? `${fieldsNamePrefix}.${n}` : n),
    [fieldsNamePrefix]
  );
  const sportsId = useFormFieldValue(getName('sportsId'));

  const { data: loadedAthlete } = useResource<Athlete>(`athletes/${athlete}`, {
    autoload: hasGetPermission && typeof athlete === 'number',
  });
  const athleteDetail = athlete && typeof athlete === 'object' ? athlete : loadedAthlete;

  const sortComparator = useCallback((a: SimpleOption, b: SimpleOption) => {
    const aScore = !a.secondary || a.secondary === '-' ? 0 : 1;
    const bScore = !b.secondary || b.secondary === '-' ? 0 : 1;
    return aScore || bScore ? bScore - aScore : defaultComparator(a, b);
  }, []);

  const sportsReducer = useCallback(
    (list: Sport[]) => {
      const aList = list.filter((it) => it.active || it.id === sportsId);
      if (athlete) {
        return aList.map((it) => ({
          id: it.id,
          name: it.name,
          secondary: athleteDetail?.sportDisciplines?.find((s) => s.sportsId === it.id)
            ? t('The selected Athlete details contain this Sport')
            : '-',
        }));
      }
      return aList;
    },
    [athleteDetail?.sportDisciplines, athlete, sportsId, t]
  );

  const disciplinesReducer = useCallback(
    (list: SportDiscipline[], selectedDisciplinesId: number | null) => {
      const aList = list.filter(
        (it) => (it.active || it.id === selectedDisciplinesId) && it.sportsId === sportsId
      );
      if (athlete) {
        return aList.map((it) => ({
          id: it.id,
          name: it.name,
          secondary: athleteDetail?.sportDisciplines?.find((s) => s.id === it.id)
            ? t('The selected Athlete details contain this Sport Discipline')
            : '-',
        }));
      }
      return aList;
    },
    [athleteDetail?.sportDisciplines, athlete, sportsId, t]
  );

  return [sortComparator, sportsReducer, disciplinesReducer];
};

export default useSportsPrioritizedByAthleteReducer;
