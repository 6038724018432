import MaterialTab, { TabProps } from '@material/react-tab';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components'; //macro

import { StyledTabIcon } from 'core/components/StyledTabIcon';

export const StyledButtonWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

type Props = TabProps & { hasError?: boolean };
const ErrorMaterialTab = forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(
  ({ hasError: _ignore, ...props }, ref: any) => {
    return <MaterialTab ref={ref} {...props} />;
  }
);

export const StyledTab = styled(ErrorMaterialTab)`
  ${(p: { hasError: boolean }) =>
    p.hasError &&
    css`
      & .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
        background-color: var(--mdc-theme-error) !important;
      }
    `}

  ${StyledTabIcon} {
    margin: 0;
    &:first-child {
      margin-right: 0.5rem;
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
`;
