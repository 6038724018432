import styled from 'styled-components'; //macro

import theme from 'app/theme';

import { hidePrint } from '../PrintOnly/styled';

export const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${theme.color.gray100};
  background: ${theme.color.white};
  align-items: center;
  flex-shrink: 0;
  display: flex;
  height: 3.5rem;
  width: 100%;
  z-index: 3;
  padding: 0;
  margin: 0;

  & > * {
    flex: 1;
  }

  &.has-search > *:last-child {
    flex: 0;
  }

  ${hidePrint}
`;

export const IconSpace = styled.div`
  padding: 0.25rem;
`;

export const RotatedIcon = styled.div`
  transform: rotate(90deg);
`;

export const StyledHeading = styled.div`
  text-overflow: ellipsis;
  text-transform: none;
  font-weight: normal;
  text-align: center;
  font-size: 1rem;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Primary = styled.div`
  align-items: center;
  position: initial;
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: flex-start;
  ${theme.mq.desktop} {
    position: relative;
  }

  & > hr {
    display: none;
  }

  & > hr:not(:first-child) {
    width: 1px;
    border: none;
    height: 100%;
    display: flex;
    margin: 0;
    background: ${theme.color.gray100};
  }
`;
