import styled from 'styled-components'; //macro

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem;
`;

export const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
