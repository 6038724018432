import styled from 'styled-components'; //macro

import theme from 'app/theme';
import AgGridPaperWrapper from 'core/components/AgGridPaperWrapper';
import Modal from 'core/components/Modal';

export const StyledModal = styled(Modal)`
  &[data-reach-dialog-overlay] > div[role='dialog'] {
    max-width: 100%;
    ${theme.mq.tablet} {
      width: 60rem;
      margin: 1rem;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  box-shadow: -1rem 0 0.5rem rgba(255, 255, 255, 0.99);
`;

export const SpaceLeft = styled.span`
  margin-left: 0.625rem;
`;

export const StyledAgGridWrapper = styled(AgGridPaperWrapper)`
  & .rowActions button {
    top: 0;
  }
`;
