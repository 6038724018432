import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';

export const Text = styled.span<{
  $color?: string;
  $fontWeight?: string;
  $fontSize?: string;
  $marginTop?: string;
  $marginBottom?: string;
  $marginRight?: string;
  $marginLeft?: string;
  $width?: string;
  $textAlign?: string;
  $display?: 'inline' | 'block';
  $uppercase?: boolean;
  $paddingLeft?: string;
  $noTruncate?: boolean;
}>`
  color: ${(props) => (props.$color ? props.$color : theme.color.baseFontColor)};
  font-weight: ${(props) => (props.$fontWeight ? props.$fontWeight : 'normal')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : 'inherit')};
  margin-bottom: ${(props) => (props.$marginBottom ? props.$marginBottom : '0rem')};
  margin-right: ${(props) => (props.$marginRight ? props.$marginRight : '0rem')};
  margin-left: ${(props) => (props.$marginLeft ? props.$marginLeft : '0rem')};
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : '0rem')};
  padding-left: ${(props) => (props.$paddingLeft ? props.$paddingLeft : '0rem')};

  ${(props) =>
    !props.$noTruncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
  display: ${(props) => (props.$display ? props.$display : 'block')};
  ${(props) =>
    props.$textAlign &&
    css`
      text-align: ${props.$textAlign};
    `}
  ${(props) =>
    props.$width &&
    css`
      width: ${props.$width};
    `}

  ${(props) =>
    props.$uppercase &&
    css`
      text-transform: uppercase;
    `}
`;
