import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { TypeOf } from 'yup';
import * as Yup from 'yup';

import { useBoolClientOption } from 'core/hooks/useClientOption';
import { MAX_ZIP_LENGTH } from 'core/utils/constant';
import { useAthleteSchemaFields } from 'personnel/containers/AthleteDetailPage/tabs/DetailTab/AthleteForm/useSchema';
import Test from 'planning/models/Test';
import { NotificationArrivalDelayReasons } from 'planning/models/TestResult';

import useIsDocumentNumberOptional from '../hooks/useIsDocumentNumberOptional';
import useArrivalDelayIsMoreThanMax from '../useArrivalDelayIsMoreThanMax';

const useAthleteSchema = (test?: Test) => {
  const { t } = useTranslation();
  const athleteSchemaFields: Omit<
    ReturnType<typeof useAthleteSchemaFields>,
    'sportDisciplines' | 'poolsId'
  > = useAthleteSchemaFields();
  const socialSecurityNumberMandatory = useBoolClientOption('socialSecurityNumberMandatory');
  const socialSecurityNumberEnabled = useBoolClientOption('enableSocialSecurityNumber');
  const athleteZipCodeMandatory = useBoolClientOption('athleteZipMandatory');
  const isCoachAndDoctorEnabled = useBoolClientOption('enableCoachDoctor');
  const contactVisible = useBoolClientOption('enableAthleteAddressInTheApp');
  const isDocumentNumberOptional = useIsDocumentNumberOptional(test);

  athleteSchemaFields.gender = athleteSchemaFields.gender.when([], {
    is: () => contactVisible,
    then: (schema) => schema.required(),
  });
  athleteSchemaFields.street = athleteSchemaFields.street.when([], {
    is: () => contactVisible,
    then: (schema) => schema.required(),
  });
  athleteSchemaFields.city = athleteSchemaFields.city.when([], {
    is: () => contactVisible,
    then: (schema) => schema.required(),
  });
  athleteSchemaFields.countriesId = athleteSchemaFields.countriesId.when([], {
    is: () => contactVisible,
    then: (schema) => schema.required(),
  });
  delete (athleteSchemaFields as any).sportDisciplines;
  delete (athleteSchemaFields as any).poolsId;

  const getArrivalDelayIsMoreThenMax = useArrivalDelayIsMoreThanMax();

  return useMemo(
    () =>
      Yup.object({
        athletesId: Yup.number().nullable().default(null),
        profile: Yup.object({
          ...athleteSchemaFields,
          sportsId: Yup.number().nullable().required().default(null),
          sportDisciplinesId: Yup.number()
            .nullable()
            .default(null)
            .when('sportsId', {
              is: (sportsId: null | number) => !!sportsId,
              then: (schema) =>
                schema.test('selectDiscipline', t('Please select sport discipline'), (v) => !!v),
            }),
          email: Yup.string()
            .when([], {
              is: () => contactVisible,
              then: (schema) => schema.email(),
            })
            .nullable()
            .default(null),
          phone: Yup.string()
            .nullable()
            .when([], {
              is: () => contactVisible,
              then: (schema) => schema.phone(),
            })
            .default(null),
          nationalityId: Yup.number().nullable().required().default(null),
          zip: Yup.string()
            .nullable()
            .max(MAX_ZIP_LENGTH)
            .when([], {
              is: () => contactVisible && athleteZipCodeMandatory,
              then: (schema) => schema.required(),
            })
            .default(null),
        }),
        arrivedAt: Yup.date()
          .nullable()
          .required()
          .test('plausibility', t('Must be later than notification date'), (value, context) => {
            // @ts-ignore
            const notification = context.from[1].value.notification;
            const notifiedAt = notification.notifiedAt as Date;

            if (!notifiedAt || !value) return true;

            return DateTime.fromJSDate(value) >= DateTime.fromJSDate(notifiedAt);
          })
          .default(null),
        doctor: Yup.string()
          .nullable()
          .when([], {
            is: () => isCoachAndDoctorEnabled,
            then: (schema) => schema.required(),
          })
          .default(null),
        coach: Yup.string()
          .nullable()
          .when([], {
            is: () => isCoachAndDoctorEnabled,
            then: (schema) => schema.required(),
          })
          .default(null),

        club: Yup.string().nullable().default(null),

        isRepresentativeNeeded: Yup.boolean().nullable().required().default(null),

        socialSecurityNumber: Yup.string()
          .max(50)
          .when([], {
            is: () => socialSecurityNumberEnabled && socialSecurityNumberMandatory,
            then: (schema) => schema.required(),
          })
          .nullable()
          .default(null),

        team: Yup.string().nullable().default(null),

        backNumber: Yup.string().nullable().max(5).default(null),

        isAthleteIdProvided: Yup.boolean().nullable().required().default(null),
        isPersonallyKnown: Yup.boolean().nullable().default(false),
        documentTypesId: Yup.number()
          .when(['isAthleteIdProvided', 'isPersonallyKnown'], {
            is: (isAthleteIdProvided: boolean | null, isPersonallyKnown: boolean | null) =>
              !isAthleteIdProvided && isPersonallyKnown,
            then: (schema) => schema,
            otherwise: (schema) => schema.required(),
          })
          .nullable()
          .default(null),
        documentNumber: Yup.string()
          .nullable()
          .default(null)
          .when(['isAthleteIdProvided', 'isPersonallyKnown'], {
            is: (isAthleteIdProvided: boolean | null, isPersonallyKnown: boolean | null) =>
              isDocumentNumberOptional(isAthleteIdProvided, isPersonallyKnown),
            then: (schema) => schema,
            otherwise: (schema) => schema.required(),
          })
          .default(null),
        nameOnDocument: Yup.string()
          .nullable()
          .when(['isAthleteIdProvided', 'isPersonallyKnown'], {
            is: (isAthleteIdProvided: boolean | null, isPersonallyKnown: boolean | null) =>
              !isAthleteIdProvided && !isPersonallyKnown,
            then: (schema) => schema.required(),
          })
          .default(null),
        documentName: Yup.string()
          .nullable()
          .when(['documentTypesId', 'isAthleteIdProvided', 'isPersonallyKnown'], {
            is: (
              documentTypesId: number | null,
              isAthleteIdProvided: boolean | null,
              isPersonallyKnown: boolean | null
            ) => !(!isAthleteIdProvided && isPersonallyKnown) && documentTypesId === 4,
            then: (schema) => schema.required(),
          })
          .default(null),
        arrivalDelayReason: Yup.mixed()
          .oneOf([1, 2, 3])
          .test('delayReasonRequired', t('This field is required'), function (value, context) {
            // @ts-ignore
            const notifiedAt = context.from[1].value.notification.notifiedAt as Date | null;
            const isRequired = getArrivalDelayIsMoreThenMax(this.parent.arrivedAt, notifiedAt);

            return !(isRequired && !value);
          })
          .nullable()
          .default(null),
        arrivalDelayComment: Yup.string()
          .nullable()
          .when('arrivalDelayReason', {
            is: NotificationArrivalDelayReasons.OTHER,
            then: (schema) => schema.required(),
          })
          .default(null),
      }),
    [
      t,
      athleteSchemaFields,
      socialSecurityNumberMandatory,
      isCoachAndDoctorEnabled,
      athleteZipCodeMandatory,
      contactVisible,
      socialSecurityNumberEnabled,
      getArrivalDelayIsMoreThenMax,
      isDocumentNumberOptional,
    ]
  );
};

export type TestResultAthlete = TypeOf<ReturnType<typeof useAthleteSchema>>;

export default useAthleteSchema;
