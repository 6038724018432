import { compareVersions } from 'compare-versions';
import { put, race, take, select } from 'redux-saga/effects';

import Event from 'app/models/Event';
import { AppState } from 'app/store';
import getEnv from 'core/functions/getEnv';
import getResourceUrl from 'core/functions/getResourcesUrl';

import {
  apiCall,
  SocketMessageAction,
  TYPE_GET_NOTIFICATIONS,
  NotificationMarkReadAction,
  TYPE_NOTIFICATION_MARK_READ,
  renewSession,
  logout,
  setNotifications,
  updateAvailable,
} from '../actions';

export function* getNotifications() {
  yield put(
    apiCall(
      `${TYPE_GET_NOTIFICATIONS}_apiCall`,
      'GET',
      getResourceUrl('/profile/events', {
        order: 'createdAt',
        sort: 'desc',
      })
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_GET_NOTIFICATIONS}_apiCall_SUCCESS`),
    error: take(`${TYPE_GET_NOTIFICATIONS}_apiCall_ERROR`),
  });

  if (success) {
    const events = success.payload.response.data || [];
    yield put(setNotifications({ unread: events.filter((i: Event) => !i.isRead).length, events }));
  }
}

export function* notificationMarkRead(action: NotificationMarkReadAction) {
  yield put(
    apiCall(
      TYPE_NOTIFICATION_MARK_READ,
      'PATCH',
      `profile/events/${action.payload.notificationId || 'all'}`,
      { isRead: true },
      {},
      false,
      { isRead: false }
    )
  );

  const { success, error } = yield race({
    success: take(TYPE_NOTIFICATION_MARK_READ + '_SUCCESS'),
    error: take(TYPE_NOTIFICATION_MARK_READ + '_ERROR'),
  });

  if (success || error) {
    const notifications: {
      events: Event[];
      unread: number;
    } = yield select((state) => state.core.notifications);

    let { events, unread } = notifications;

    if (action.payload.notificationId === undefined) {
      events = (events || []).map((it) => ({ ...it, isRead: true }));
      unread = 0;
    } else {
      const index = (events || []).findIndex((it) => it.id === action.payload.notificationId);
      if (index > -1 && events && events[index]) {
        events = [...events];
        events[index] = { ...events[index], isRead: true };
        unread = Math.max(unread - 1, 0);
      }
    }

    yield put(setNotifications({ events, unread }));
  }
}

export function* watchSocketMessages(action: SocketMessageAction) {
  switch (action.payload.eventName) {
    case 'notification':
      const notificationsState: AppState['core']['notifications'] = yield select(
        (state: AppState) => state.core.notifications
      );
      let events = [...(notificationsState.events || [])];

      const payload = action.payload.payload as unknown as Event;

      let unread = notificationsState.unread || 0;
      // If an update to the existing one arrived, we no longer consider the old one
      if (events.some((e) => e.id === payload.id)) {
        events = events.filter((e) => e.id !== payload.id);
      } else {
        unread += 1;
      }

      events.unshift(payload);

      yield put(
        setNotifications({
          ...notificationsState,
          unread,
          events,
        })
      );
      break;

    case 'versionUpdate':
      const currentVersion = getEnv('VERSION');
      const newVersion = (action.payload.payload as { version: string }).version;
      if (currentVersion && compareVersions(currentVersion, newVersion) === -1) {
        yield put(updateAvailable(true));
      }
      break;

    case 'sessionExpired':
      yield put(renewSession());
      break;
    case 'sessionDeleted':
      yield put(logout());
      break;
  }
}
