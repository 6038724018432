import styled from 'styled-components'; //macro

import theme from 'app/theme';
import IconButton from 'core/components/IconButton';

export const ColoredIconButton = styled(IconButton)`
  color: ${({ color }: { color: keyof typeof theme.color }) => theme.color[color]};
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`;
