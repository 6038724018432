import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { DeepPartial, Reducer } from 'redux';
import createSagaMiddleware, { Saga } from 'redux-saga';

import { State } from './prepareStore';

const prepareStore = (
  rootReducer: Reducer,
  sagas: Saga,
  history: History,
  preloadedState?: DeepPartial<State>
) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false, serializableCheck: false, immutableCheck: false })
        .concat(routerMiddleware(history) as any)
        .concat(sagaMiddleware),
    reducer: rootReducer,
    preloadedState,
    devTools: true,
  });

  sagaMiddleware.run(sagas);

  return store;
};

export default prepareStore;
