import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';

export const ToolbarBadge = styled.button<{
  active: boolean;
}>`
  background-color: ${({ active }) => theme.color[active ? 'primary' : 'gray100']};
  color: ${({ active }) => (active ? 'white' : theme.color.baseFontColor)};
  display: inline-block;
  border-radius: 1rem;
  font-size: 0.75rem;
  line-height: 1;
  white-space: nowrap;
  border: 0;
  margin-right: 0.438rem;
  padding: 0.438rem 0.625rem;
  cursor: pointer;
  text-transform: capitalize;
`;

export const Wrapper = styled.div`
  white-space: nowrap;
  display: flex;
  width: 100%;
  overflow: auto hidden;
  scrollbar-width: thin;
`;

export const BadgeWrapper = styled.div<{ $isMac: boolean }>`
  display: inline-block;
  margin: auto;
  margin-left: 0.438rem;
  flex: 1;

  ${({ $isMac }) =>
    $isMac &&
    css`
      padding-bottom: 10px;
      padding-top: 10px;
    `}
`;
