import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TeamMemberStatus } from 'planning/models/TeamMember';

/**
 * Human readable team member status
 */
const useGetStatusName = (): ((status?: TeamMemberStatus) => string) => {
  const { t } = useTranslation();

  return useCallback(
    (status?: TeamMemberStatus): string => {
      switch (status) {
        case TeamMemberStatus.ACCEPTED:
          return t('Accepted');

        case TeamMemberStatus.AWAITING:
          return t('Awaiting');

        case TeamMemberStatus.CONFIRMED:
          return t('Confirmed');

        case TeamMemberStatus.DECLINED:
          return t('Declined');

        case TeamMemberStatus.SELECTED:
          return t('Selected');

        default:
          return t('Unknown');
      }
    },
    [t]
  );
};

export default useGetStatusName;
