import styled from 'styled-components'; //macro

export const Container = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ padding }) => padding || `3rem`};
`;

export const StyledIcon = styled.div`
  width: 10rem;
`;

export const StyledTitle = styled.span`
  font-size: 1.75rem;
  margin: 1.5rem 0 0.5rem 0;
`;
