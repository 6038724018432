import styled from 'styled-components'; //macro

import theme from 'app/theme';
import MaterialIcon from 'core/components/MaterialIcon';

export const Checkbox = styled(MaterialIcon)`
  margin-left: -3px;
  margin-top: -3px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: ${(p: { disabled?: boolean }) =>
    p.disabled ? theme.color.gray200 : theme.color.baseFontColor};
`;

export const Separator = styled.hr`
  display: block;
  height: 1px;
  margin: 0.25rem auto;
  border: none;
  background-color: ${theme.color.gray200};
`;

/** Loading indicator row wrapper */
export const LoadingRow = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  overflow: hidden;
  padding: 0 16px;
  display: flex;

  & > svg {
    width: 100%;
    height: 13px;
  }
`;

export const HiddenInput = styled.input`
  position: absolute;
  left: -100%;
  opacity: 0;
`;
