import styled from 'styled-components'; //macro

export const EmptyContent = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  max-width: 500px;
  flex-direction: column;
  & > div {
    text-align: center;
  }
`;

export const EmptyContentText = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 3rem;
  font-weight: 600;
`;

export const Img = styled.div`
  width: 20rem;
`;
