import styled from 'styled-components'; //macro

import theme from 'app/theme';

export const FieldGroup = styled.div<{ hasError: boolean }>`
  border: ${(p) => (p.hasError ? `1px solid var(--mdc-theme-error)` : 'none')};
  border: 1px solid ${theme.color.gray300};
  padding: 1rem;
  border-radius: 0.25rem;

  .rdw-editor-main {
    height: 22rem;
  }

  .rdw-option-wrapper {
    padding: 1rem;
  }

  .rdw-editor-toolbar {
    border: none;
    padding-left: 0;
  }
`;

export const StyledLabel = styled.label`
  color: ${theme.color.gray400};
  font-size: 0.8125rem;
`;
