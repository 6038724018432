import ContentLoader from 'react-content-loader';
import styled from 'styled-components'; //macro

export const IconContentLoader = styled(ContentLoader as any)`
  width: 20rem;
  height: 20rem;
  align-self: center;
  margin: auto;
  display: flex;
`;
