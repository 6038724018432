import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';

const FieldGroup = styled.div<{ hasError?: boolean; fullHeight?: boolean }>`
  flex-direction: column;
  width: 100%;
  display: flex;
  & > label {
    color: ${(p) => (p.hasError ? theme.color.error : theme.color.gray200)};
  }

  ${(p) =>
    p.fullHeight &&
    css`
      height: 100%;
    `}

  .mdc-text-field {
    border-radius: 0 !important;
  }

  .mdc-text-field--textarea {
    width: 100%;
  }

  input {
    border: 1px solid ${(p) => (p.hasError ? theme.color.error : theme.color.gray200)};

    overflow: hidden;
    &:not(:placeholder-shown) + span {
      color: ${(p) => (p.hasError ? theme.color.error : theme.color.secondary800)};
    }
  }
`;

export default FieldGroup;
