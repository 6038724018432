import styled from 'styled-components'; //macro

export const ErrorWrapper = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(p) => (p.fullScreen ? '100vw' : '100%')};
  height: 100vh;
  height: ${(p) => (p.fullScreen ? 'calc(var(--vh, 1vh) * 100)' : '100%')};

  > * {
    max-width: 27rem;
  }

  p {
    text-align: center;
  }
`;
