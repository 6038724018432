import axios, { AxiosError, AxiosResponse } from 'axios';
import { compareVersions } from 'compare-versions';
import { Operation } from 'fast-json-patch';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { put } from 'redux-saga/effects';

import { ApiCallAction, apiCallError, apiCallSuccess, updateAvailable } from 'core/actions';
import getEnv from 'core/functions/getEnv';
import { t } from 'core/i18n';

import createDiff from './createDiff';
import { transformStringsToDateTime } from './dateTimeTransformations';

type ErrorResponse = { ok?: boolean; message?: string; code?: string; error: Error };

export const safeMethods = ['GET', 'OPTIONS', 'HEAD'];

const commonHeaders = {
  'X-Request-Platform': `${getEnv('NAME')} Frontend ${getEnv('VERSION')}`,
};

/**
 * Attempt to get fresh CSRF token set in cookie by doing dumb call to API
 */
const refreshCSRFToken = (baseUrl?: string) =>
  axios.get('/', {
    baseURL: baseUrl || getEnv('API_URL'),
    headers: {
      'Accept-Language': i18next.language,
      ...commonHeaders,
    },
  });

/**
 * Get CSRF token set in the cookie
 */
export const getCSRFToken = () => {
  const csrfCookie = document.cookie
    .split(';')
    .map((c) => c.trim().split('='))
    .find((c) => c[0] === 'ct');
  return csrfCookie ? csrfCookie[1] : undefined;
};

/**
 * Check if the API response indicates our version is outdated
 */
const isOutdated = (response?: AxiosResponse) => {
  if (getEnv('NODE_ENV') === 'development') {
    return false;
  }
  if (response?.headers?.['x-latest-platform']) {
    const currentVersion = getEnv('VERSION');
    const newVersion = response.headers['x-latest-platform'];
    if (currentVersion && compareVersions(currentVersion, newVersion) === -1) {
      return true;
    }
  }

  return false;
};

export default function* apiCall(action: ApiCallAction) {
  let csrfToken = getCSRFToken();

  if (!csrfToken && !safeMethods.includes(action.payload.method)) {
    // Try to get the token once
    try {
      // Try to get the token once
      yield refreshCSRFToken(action.payload?.config?.baseURL);
    } catch (error) {
      const e = error as AxiosError<any>;
      const message =
        e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : e.message;

      if (action.payload.showError) {
        toast.error(t('Communication failed: {{message}}', { message }));
      }

      yield put(apiCallError(action.payload.actionPrefix, e, e.response));
      return;
    }

    csrfToken = getCSRFToken();
  }

  let data: Operation[] | unknown;
  if (action.payload.method === 'PATCH' && action.payload.diffPatch) {
    if (!action.payload.previousData) {
      throw new Error('Previous data are required when doing PATCH on entity.');
    }

    const diff = createDiff(
      action.payload.data,
      action.payload.previousData,
      action.payload.ignoredPreviousParameters
    );
    const { customOperations } = action.payload;

    if ((!diff || diff.length === 0) && customOperations.length === 0) {
      toast.info('There are no changes to be saved.');
      yield put(
        apiCallError(action.payload.actionPrefix, new Error('There are no changes to be saved.'))
      );
      return;
    }
    data = (diff || []) as Operation[];

    // Include custom operations: Remove and Add
    customOperations.forEach((op) => {
      if (op.operation === 'remove') {
        (data as Operation[]).push({
          op: 'replace',
          path: `/${op.fieldName.replace(/\./g, '/')}`,
          value: action.payload.data[op.fieldName],
        });
      }

      if (op.operation === 'add') {
        const indexOfCurrentFieldChange = (data as Operation[]).findIndex(
          (it) => it.path === `/${op.fieldName.replace(/\./g, '/')}`
        );
        const addOp = (data as Operation[])[indexOfCurrentFieldChange];
        if (indexOfCurrentFieldChange > -1 && 'value' in addOp && Array.isArray(addOp.value)) {
          (data as Operation[]).splice(indexOfCurrentFieldChange, 1);
          const addValues = addOp.value as [];

          addValues.forEach((value) => {
            (data as Operation[]).push({
              op: 'add',
              path: `/${op.fieldName.replace(/\./g, '/')}/-`,
              value,
            });
          });
        }
      }
    });
  } else {
    data = action.payload.data;
  }

  const requestConfig = {
    baseURL: getEnv('API_URL'),
    url: action.payload.url,
    method: action.payload.method,
    data: ['PUT', 'POST', 'PATCH'].includes(action.payload.method) ? data : undefined,
    params: action.payload.method === 'GET' ? data : undefined,
    ...action.payload.config,
    headers: {
      ...commonHeaders,
      'Accept-Language': i18next.language,
      'X-XSRF-Token': csrfToken || '',
      ...action.payload.config.headers,
    },
  };

  let response: AxiosResponse;
  try {
    response = yield axios.request(requestConfig);
  } catch (error) {
    const e = error as AxiosError<ErrorResponse>;
    if (
      e.response &&
      e.response.status === 403 &&
      e.response.data &&
      e.response.data.code === 'EBADCSRFTOKEN'
    ) {
      try {
        yield refreshCSRFToken();
      } catch (error) {
        const e = error as AxiosError<ErrorResponse>;
        const message =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : e.message;

        if (action.payload.showError) {
          toast.error(t('Communication failed: {{message}}', { message }));
        }

        yield put(apiCallError(action.payload.actionPrefix, e, e.response));
        return;
      }
      csrfToken = getCSRFToken();
      if (csrfToken) {
        requestConfig.headers['X-XSRF-Token'] = csrfToken;
      }

      // Retry the request
      try {
        response = yield axios.request(requestConfig);
      } catch (error) {
        const e = error as AxiosError<ErrorResponse>;
        const message =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : e.message;

        if (action.payload.showError) {
          toast.error(t('Communication failed: {{message}}', { message }));
        }
        yield put(apiCallError(action.payload.actionPrefix, e, e.response));
        return;
      }
    } else {
      const message =
        e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : e.message;
      if (action.payload.showError) {
        toast.error(t('Communication failed: {{message}}', { message }));
      }

      if (isOutdated(e.response)) {
        toast.warning(
          t(
            'You are using an old version of the application, which is likely the reason for the error. Please click here to reload.'
          ),
          {
            onClick: () => window.location.reload(),
          }
        );
      }

      yield put(apiCallError(action.payload.actionPrefix, e, e.response));
      return;
    }
  }

  // Process date/datetime fields
  if (response.data) {
    transformStringsToDateTime(response.data);
  }

  // Check if the latest version from the API isn't newer
  if (isOutdated(response)) {
    yield put(updateAvailable(true));
  }

  yield put(apiCallSuccess(action.payload.actionPrefix, response, action.payload.url));
}
