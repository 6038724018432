import styled from 'styled-components'; //macro

import theme from 'app/theme';
import Badge from 'core/components/Badge';

const DropDownBadge = styled(Badge)`
  .mdc-list--dense &.mdc-list-item__graphic {
    color: ${theme.color.white};
    font-size: 0.8rem;
    width: unset;
    height: auto;
    margin-right: 1rem;
  }
`;

export default DropDownBadge;
