import { useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { entities } from 'app/entity';
import User, { UserContactType } from 'app/models/User';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import Modal from 'core/components/Modal';
import Switch from 'core/components/Switch';
import usePermission from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import { TeamMemberNotification } from 'planning/models/TeamMember';

import { NotificationType, TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';

import { InvitationTogglesWrapper } from './styled';

interface Props {
  type: TeamMemberType;
  members?: TeamMemberData[];
  onClose: () => void;
}

const InvitationsModal: FC<Props> = ({ type, members, onClose }) => {
  const { t } = useTranslation();
  const [viaEmail, setViaEmail] = useState(false);
  const [viaSms, setViaSms] = useState(false);
  const { data } = useResources<User>(entities.user.api().list, { autoload: !!members });
  const users = data?.filter((user) => members?.some((m) => m.usersId === user.id)) || [];
  const canSeeUser = usePermission('users:get[actions:contacts]');

  useEffect(() => {
    // We can pre-fill current values only if one member is being edited
    if (members?.length === 1) {
      setViaEmail(
        members[0].invitations.some((i) => i.type === NotificationType.EMAIL && !i.createdAt)
      );
      setViaSms(
        members[0].invitations.some((i) => i.type === NotificationType.SMS && !i.createdAt)
      );
    }
  }, [members]);

  const teamMemberTypeFormikKey =
    type === TeamMemberType.INVITED ? 'invitedMembers' : 'assignedMembers';
  const formik = useFormikContext<TeamFormData>();

  const handleConfirm = useCallback(() => {
    const memberIds = new Set(members?.map((m) => m.usersId) || []);

    const newNotifications: TeamMemberNotification[] = [];
    if (viaEmail) {
      newNotifications.push({ type: NotificationType.EMAIL });
    }
    if (viaSms) {
      newNotifications.push({ type: NotificationType.SMS });
    }

    formik.setValues({
      ...formik.values,
      [teamMemberTypeFormikKey]: formik.values[teamMemberTypeFormikKey].map((member) => {
        if (!memberIds.has(member.usersId)) {
          return member;
        }

        if (type === TeamMemberType.INVITED) {
          const invitations = member.invitations.filter((i) => !!i.createdAt);
          return {
            ...member,
            invitations: [...invitations, ...newNotifications],
          };
        }

        if (type === TeamMemberType.ASSIGNED) {
          const assignmentNotifications = member.assignmentNotifications.filter(
            (i) => !!i.createdAt
          );
          return {
            ...member,
            assignmentNotifications: [...assignmentNotifications, ...newNotifications],
          };
        }

        throw new Error('Invalid member type');
      }),
    });
  }, [type, teamMemberTypeFormikKey, formik, members, viaEmail, viaSms]);
  const noPhone =
    users?.some((u) => u.hasSmsContact) ||
    users.some(
      (u) =>
        !u.contacts.some(
          (c) => c.type === UserContactType.MOBILE || c.type === UserContactType.PHONE
        )
    );
  const noPhoneAndSingleAction = noPhone && users.length === 1;
  const noPhoneAndBulkAction = noPhone && users.length > 1;

  const link = canSeeUser ? (
    <Link to={entities.user.urls().detail(users[0]?.id)} target="_blank">
      {t('under their account')}
    </Link>
  ) : (
    t('under their account')
  );

  const FeedbackMessageComponent = () => {
    if (noPhoneAndSingleAction) {
      return (
        <FeedbackMessage $type="warning">
          {t('The user has no phone number set')} {link}.
        </FeedbackMessage>
      );
    } else if (noPhoneAndBulkAction) {
      return (
        <FeedbackMessage $type="warning">
          {t("If a user doesn't have a phone number set, an e-mail will be sent instead.")}
        </FeedbackMessage>
      );
    }

    return null;
  };

  return (
    <Modal
      ariaLabel={
        type === TeamMemberType.INVITED
          ? t('Team Member invitation editing dialog')
          : t('Team Member assignment notification editing dialog')
      }
      title={
        type === TeamMemberType.INVITED ? t('Planned Invitations') : t('Planned Notifications')
      }
      open={!!members}
      onClose={() => {
        setViaEmail(false);
        setViaSms(false);
        onClose();
      }}
      onConfirm={handleConfirm}
      onClear={() => {
        setViaEmail(false);
        setViaSms(false);
      }}
    >
      <InvitationTogglesWrapper>
        <p>
          {type === TeamMemberType.INVITED
            ? t('Invitations are sent after saving.')
            : t('Notifications are sent after saving.')}
        </p>

        <Switch
          id="invitation-email"
          name="invitation-email"
          label={t('Email')}
          value={viaEmail}
          onChange={setViaEmail}
        />

        <Switch
          id="invitation-sms"
          name="invitation-sms"
          label={t('SMS')}
          value={viaSms}
          onChange={setViaSms}
          disabled={noPhoneAndSingleAction ? noPhoneAndSingleAction : false}
        />
        <FeedbackMessageComponent />
      </InvitationTogglesWrapper>
    </Modal>
  );
};

export default InvitationsModal;
