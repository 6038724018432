import styled from 'styled-components'; //macro

import theme from 'app/theme';
import { invisiblePrint } from 'core/components/PrintOnly/styled';

export const StyledCounts = styled.span`
  font-size: 0.875rem;
  margin-right: 0.5rem;
  white-space: nowrap;

  /* Visible only from tablet */
  display: none;
  ${theme.mq.tablet} {
    display: block;
  }

  ${invisiblePrint}
`;
