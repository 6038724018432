import { ForwardedRef, MutableRefObject } from 'react';
import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';

import { ValueStyle } from '.';

export const Wrapper = styled.div.attrs(({ ref, children, className }) => ({
  ref,
  children,
  className,
}))<{
  $valueStyle: ValueStyle;
  $dense?: boolean;
  $minWidth?: number | string;
  enableVerticalScroll?: boolean;
  ref?: ForwardedRef<HTMLDivElement> | MutableRefObject<HTMLDivElement>;
}>`
  min-height: ${(props) => (props.$dense ? '3rem' : '3.5rem')};
  width: 100%;
  background: ${theme.color.gray};
  justify-content: space-between;
  border-radius: 0.25rem;
  padding: 1rem;
  align-items: center;
  display: flex;
  border: 1px solid ${theme.color.gray100};
  color: ${theme.color.gray300};
  overflow: visible;

  ${({ $minWidth }) =>
    $minWidth !== undefined &&
    css`
      min-width: ${$minWidth};
    `}

  i {
    align-self: center;
    color: ${theme.color.gray300};
  }

  ${(props) =>
    props.$dense &&
    css`
      & > .mdc-icon-button {
        margin: -0.5rem 0;
      }
    `}

  ${({ $valueStyle }) =>
    $valueStyle !== undefined &&
    css`
      background: ${$valueStyle === 'new'
        ? theme.color.successBackground
        : theme.color.errorBackground};
      border: 1px solid ${$valueStyle === 'new' ? theme.color.success50 : theme.color.error50};
      color: ${$valueStyle === 'new' ? theme.color.success : theme.color.error};

      .material-icons {
        color: ${$valueStyle === 'new' ? theme.color.success : theme.color.error};
      }
    `}


  ${({ enableVerticalScroll }) =>
    enableVerticalScroll &&
    css`
      overflow-y: auto;
    `}
`;

export const StyledValue = styled.div.attrs(({ children, className }) => ({
  children,
  className,
}))<{
  valueStyle: ValueStyle;
  hasValue?: boolean;
  multiline?: boolean;
  multilineRows?: number;
  enableVerticalScroll?: boolean;
}>`
  color: ${theme.color.gray400};
  line-height: 1.3rem;
  min-height: ${({ multilineRows }) => (multilineRows ? multilineRows * 1.3 : 1.3)}rem;
  width: 100%;

  ${(props) =>
    !props.multiline && !props.enableVerticalScroll
      ? css`
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        `
      : css`
          white-space: pre-wrap;
        `}

  ${({ hasValue }) =>
    !hasValue &&
    css`
      position: absolute;
      height: 0;
    `}

    ${({ valueStyle }) =>
    valueStyle !== undefined &&
    css`
      color: ${valueStyle === 'new' ? theme.color.success : theme.color.error};
    `}
`;

export const StyledLabel = styled.label<{
  valueStyle: ValueStyle;
}>`
  margin: 0;
  font-size: 1rem;
  color: ${theme.color.gray400};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;

  ${({ valueStyle }) =>
    valueStyle !== undefined &&
    css`
      color: ${valueStyle === 'new' ? theme.color.success : theme.color.error};
    `}
`;

export const StyledField = styled.div<{
  hasLabel?: boolean;
  hasValue?: boolean;
  hasStartIcon?: boolean;
  hasEndIcon?: boolean;
  enableVerticalScroll?: boolean;
}>`
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  display: flex;
  flex: 1;

  ${({ hasEndIcon, hasStartIcon }) => css`
    max-width: calc(100% - ${hasEndIcon && hasStartIcon ? '4rem' : 0});
  `};

  ${({ hasValue, hasLabel }) =>
    hasValue
      ? css`
          ${StyledLabel} {
            position: absolute;
            font-size: 0.75rem;
            margin-top: -0.45rem;
            line-height: 1.2;
          }
          ${StyledValue} {
            margin-top: ${hasLabel ? '0.5rem' : 0};
            margin-bottom: -0.5rem;
          }
        `
      : css`
          ${StyledLabel} {
            position: relative;
            height: auto;
            line-height: 1.2;
          }
        `};

  ${({ enableVerticalScroll }) =>
    enableVerticalScroll &&
    css`
      max-height: 1.2rem;
    `}
`;

export const Start = styled.div`
  margin-right: 0.75rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .mdc-icon-button {
    margin: -0.75rem 0;
  }

  & > *:not(:first-child) {
    margin-left: 1rem;
  }

  .mdc-icon-button:first-child {
    margin-left: -0.75rem;
  }
`;

export const End = styled(Start)`
  margin-left: 0.75rem;

  .mdc-icon-button:last-child {
    margin-right: -0.75rem;
  }
`;
