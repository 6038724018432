import styled from 'styled-components'; //macro

export const Cell = styled.div`
  flex: 1;

  &:not(:last-child) {
    padding-right: 1rem;
  }
`;

export const Row = styled.div.attrs({ 'data-cy': 'Row' })`
  display: flex;
  width: 100%;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
