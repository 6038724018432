import styled, { AnyStyledComponent, css } from 'styled-components'; //macro

import theme from 'app/theme';
import Button, { ButtonProps } from 'core/components/Button';
import { invisiblePrint } from 'core/components/PrintOnly/styled';

export const Container = styled.div<{ activated: boolean }>`
  width: 100%;
  background: ${(p) => (p.activated ? theme.color.primary50 : theme.color.white)};
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  border-bottom: 1px solid ${theme.color.gray100};
`;

export const ToolbarButton = styled(Button as AnyStyledComponent)<
  ButtonProps & { $highlighted: boolean; $pullRight: boolean }
>`
  ${({ $pullRight }) =>
    $pullRight
      ? css`
          margin-left: auto;
        `
      : ''}

  --mdc-theme-primary: ${({ $highlighted }) =>
    $highlighted ? theme.color.primary : theme.color.baseFontColor};

  ${invisiblePrint}
`;

export const ModalSubtitle = styled.h3<{ bottomMargin?: boolean; topMargin?: boolean }>`
  color: ${theme.color.baseFontColor};
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: ${(p) => (p.bottomMargin ? '0.5rem' : 0)};
  margin-top: ${(p) => (p.topMargin ? '1rem' : 0)};
`;
