import useClientOption from 'core/hooks/useClientOption';
import Test from 'planning/models/Test';
import { AthleteDocumentNumber } from 'settings/models/Setting';

import useHideAthleteDocumentNumber from './useHideAthleteDocumentNumber';

const useGetIsDocumentNumberOptional = (test?: Test) => {
  const hideAthleteDocumentNumber = useHideAthleteDocumentNumber();
  const athleteDocumentNumber = useClientOption(
    'athleteDocumentNumber'
  ) as AthleteDocumentNumber | null;
  const is3gen = test?.use3genApp === true;

  return (isAthleteIdProvided: boolean | null, isPersonallyKnown: boolean | null) => {
    if (is3gen) {
      return !isAthleteIdProvided || athleteDocumentNumber !== AthleteDocumentNumber.MANDATORY;
    }

    if (hideAthleteDocumentNumber && isAthleteIdProvided) {
      return true;
    }

    return !isAthleteIdProvided && isPersonallyKnown;
  };
};

export default useGetIsDocumentNumberOptional;
