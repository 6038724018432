import Select, { SelectProps } from '@material/react-select';
import { FC, forwardRef, Ref } from 'react';
import styled, { css } from 'styled-components'; //macro

type Props = SelectProps & { disablePointer?: boolean; dense?: boolean };

const SS = forwardRef((props: Props, ref: Ref<HTMLSelectElement>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disablePointer, dense, ...p } = props;
  return <Select {...p} ref={ref as any} />;
});

export const MaterialSelect: FC<Props> = styled(SS)`
  min-width: 5rem;
  height: ${(p: any) => (p.dense ? '3rem' : '3.5rem')};
  width: 100%;

  ${(p: Props) =>
    p.disablePointer &&
    css`
      pointer-events: none; /*  Hide options on mouse events */
    `}

  ${(p: Props) =>
    p.dense &&
    css`
      & > select {
        padding: 12px 12px 7px !important;
        height: 3rem;
      }
    `}
`;

export const StyledWrapper = styled.div<{ enablePointer?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;

  ${(p) =>
    p.enablePointer &&
    css`
      & > * {
        z-index: 1;
      }
      &:after {
        position: absolute;
        cursor: pointer;
        height: 100%;
        width: 100%;
        content: '';
        z-index: 2;
        left: 0;
        top: 0;
      }
    `}
`;
