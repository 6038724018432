import { call, select } from 'redux-saga/effects';

import { AppState } from 'app/store';
import { publicPathNames, urls } from 'app/urls';

import bootstrap from './bootstrap';
import getEnv from 'core/functions/getEnv';

function* persistRehydrate() {
  const isLoggedIn: true | undefined = yield select((s: AppState) => s.core.isLoggedIn);

  if (
    !isLoggedIn ||
    getEnv('APP_MODE') === 'identity' ||
    /[?&]login/.test(window.location.search)
  ) {
    return;
  }

  const pathname: string = yield select((s: AppState) => s.router.location.pathname);

  if (
    pathname === urls.dashboard ||
    pathname === urls.login ||
    !publicPathNames.find((p: any) => pathname.startsWith(p))
  ) {
    yield call(bootstrap, true);
  }
}

export default persistRehydrate;
