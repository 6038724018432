import { RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'core/containers/DataGrid/styles.scss';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components'; //macro

import IconLoader from 'app/components/IconLoader';
import AgGridPaperWrapper from 'core/components/AgGridPaperWrapper';
import PaperContainer from 'core/components/PaperContainer';
import Tabs, { TabOption } from 'core/components/Tabs';
import { onCopyableRowClicked } from 'core/containers/DataGrid/helpers';

import useTableDefinitions from './useTableDefinitions';

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export interface PaperTableTabOption extends TabOption {
  dataLoading?: boolean;
  type: keyof ReturnType<typeof useTableDefinitions>;
}

export type PaperTableTabsOptions = Array<PaperTableTabOption>;

interface Props {
  onSelect?: (event: RowClickedEvent, tabType: string) => void;
  tabs: PaperTableTabsOptions;
}

const PaperTableTabs: FC<Props> = ({ onSelect, tabs: tabsDefinition }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tables = useTableDefinitions();

  const tabs = useMemo(() => tabsDefinition.filter((tab) => !tab.hidden), [tabsDefinition]);

  const tabsCount = useMemo(() => tabs.filter((t) => !t.hidden), [tabs]);

  const currentTab = tabs?.[activeTab];

  const tableDef = tables[currentTab!.type]();

  const AgGridTable = () => (
    <AgGridPaperWrapper fullHeight>
      <AgGridReact<any>
        onGridReady={({ api }) => api.sizeColumnsToFit()}
        defaultColDef={{ sortable: true }}
        columnDefs={tableDef.columns}
        suppressHorizontalScroll={false}
        suppressCellFocus
        tooltipShowDelay={500}
        rowData={currentTab!.data || []}
        onRowClicked={(e) =>
          onCopyableRowClicked(e, onSelect ? (e) => onSelect(e, currentTab.type) : undefined)
        } // TODO: add cursor: pointer
        enableCellTextSelection
        ensureDomOrder
      />
    </AgGridPaperWrapper>
  );

  if (tabsCount.length < 2)
    return (
      <PaperContainer
        icons={currentTab.icons}
        badgePrimary={currentTab.data && currentTab.data.length.toString()}
        title={tableDef.title}
        fullHeight
      >
        {currentTab.dataLoading ? <IconLoader /> : <AgGridTable />}
      </PaperContainer>
    );

  return (
    <PaperContainer fullHeight>
      <TabsWrapper>
        <Tabs tabs={tabs} tabsName="reports" activeTab={activeTab} setActiveTab={setActiveTab} />
        {currentTab.dataLoading ? <IconLoader /> : <AgGridTable />}
      </TabsWrapper>
    </PaperContainer>
  );
};

export default PaperTableTabs;
