import styled, { css } from 'styled-components'; //macro

export interface StyledMaterialIconProps {
  color?: string;
  size?: number;
  disabled?: boolean;
}

const StyledMaterialIcon = styled.span<StyledMaterialIconProps>`
  ${(p) =>
    p.size
      ? css`
          font-size: ${p.size}rem;
        `
      : ''};
  color: ${(p) => p.color || 'inherit'};
  vertical-align: middle;
  ${(p) => p.disabled && { opacity: 0.5 }};

  [aria-hidden='true'] {
    visibility: hidden;
  }
`;

export default StyledMaterialIcon;
