import { goBack } from 'connected-react-router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import { AppState } from 'app/store';
import { FlexRow } from 'core/components/FlexUtils';
import MaterialIcon from 'core/components/MaterialIcon';
import SecondaryPage from 'core/components/SecondaryPage';
import Spacer from 'core/components/Spacer';
import { canGoBack } from 'core/history';
import { NotFound } from 'core/illustrations/NotFound';

interface OwnProps extends RouteComponentProps {
  title?: string;
}

type Props = DispatchProps & OwnProps;

const ErrorPage: FC<Props> = ({ dispatchToPreviousPage, title }) => {
  const { t } = useTranslation();

  return (
    <>
      <SecondaryPage>
        <FlexRow horizontalAlign="center">
          <NotFound />
        </FlexRow>
        <Spacer $marginTop="3rem">
          <SecondaryPage.Title>{title}</SecondaryPage.Title>
        </Spacer>

        {canGoBack() ? (
          <SecondaryPage.Actions>
            <SecondaryPage.Link onClick={dispatchToPreviousPage}>
              <MaterialIcon icon="arrow_back" />
              {t('Go back')}
            </SecondaryPage.Link>
          </SecondaryPage.Actions>
        ) : (
          <SecondaryPage.Actions>
            <SecondaryPage.Link to={'/'}>
              <MaterialIcon icon="arrow_back" />
              {t('Go to Home Page')}
            </SecondaryPage.Link>
          </SecondaryPage.Actions>
        )}
      </SecondaryPage>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchToPreviousPage: () => dispatch(goBack() as Action),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter<OwnProps, any>(
  connect<undefined, DispatchProps, OwnProps, AppState>(undefined, mapDispatchToProps)(ErrorPage)
);
