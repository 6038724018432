import styled from 'styled-components'; //macro

export const StyledSelectedText = styled.div.attrs((className) => ({ className }))`
  &.mdc-select {
    width: 100%;

    .mdc-select__selected-text {
      width: 100%;
      min-width: ${({ minWidth }) => minWidth || '15rem'};
    }
  }
`;

export const StyledWrapper = styled.div`
  flex-direction: column;
  position: relative;
  display: flex;
`;

export const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background: var(--mdc-theme-white);

  .DayPicker-wrapper {
    padding: 0;
  }

  .DayPicker-Months {
    flex-wrap: nowrap;
  }

  .DayPicker-Month {
    margin: 1em;
    border-collapse: inherit;
  }

  .DayPicker-Day--today {
    color: inherit;
  }

  .DayPicker-Day,
  .DayPicker-WeekNumber {
    font-size: 0.75em;
    width: 3em;
    height: 3em;
    padding: 0;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: var(--mdc-theme-primary);
    color: var(--mdc-theme-on-primary);
  }

  .DayPicker:not(.DayPicker--interactionDisabled) {
    &.DayPicker-Day:not(.DayPicker-Day--disabled) {
      &:not(.DayPicker-Day--selected) {
        &:not(.DayPicker-Day--outside) {
          &:hover {
            background-color: var(--mdc-theme-gray);
          }
        }
      }
    }
  }

  .DayPicker--Range {
    .DayPicker-Weekdays,
    .DayPicker-Body {
      margin: 0 1em;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--start) {
      &:not(.DayPicker-Day--end) {
        &:not(.DayPicker-Day--outside) {
          background-color: var(--mdc-theme-primary-light);
          color: var(--mdc-theme-primary);
        }
      }
    }

    .DayPicker-Day--start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    .DayPicker-Day--end {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    .DayPicker-Month:first-child {
      .DayPicker-Result {
        position: absolute;
        white-space: nowrap;
        overflow: visible;
      }

      .DayPicker-MonthNavigation {
        margin-top: 1.8125em;
      }

      .DayPicker-MonthArrow:last-child {
        visibility: hidden;
      }
    }

    .DayPicker-Month:not(:first-child) {
      .DayPicker-Year,
      .DayPicker-Result {
        visibility: hidden;
      }

      .DayPicker-MonthArrow:first-child {
        visibility: hidden;
      }

      .DayPicker-Result {
        height: 1.8125rem;
      }
    }
  }
`;
