import styled from 'styled-components'; //macro

const StyledAnnotation = styled('small')`
  position: relative;

  a {
    color: var(--mdc-theme-primary);
    text-decoration: none;
  }
`;

export { StyledAnnotation };
