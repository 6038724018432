import styled, { createGlobalStyle, css } from 'styled-components'; //macro

import backgroundImage from 'app/assets/img/img_login-screen.jpg';
import theme from 'app/theme';

export const getMediaQuery = (breakpoint: number) => `@media (max-width: ${breakpoint * 10}px)`;

export const FullscreenRouteWrap = styled.div`
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-width: 100%;
  display: flex;
`;

export const ContentWrap = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1;

  ${getMediaQuery(theme.breakpoints.desktop)} {
    flex: 2;
  }
`;

export const ImgGoesHere = styled.div`
  background: url(${backgroundImage}) left center / cover no-repeat;
  background-size: cover;
  position: relative;
  flex-grow: 0;
  width: 25%;
  align-items: flex-end;
  display: flex;
`;

export const LogoWrap = styled.div<{ dark?: boolean }>`
  margin: 2rem;
  svg {
    height: 5rem;
    max-width: 100%;
  }

  ${({ dark }) =>
    dark &&
    css`
      --logo-color: var(--logo-dark-color);
    `}
`;

export const DesktopVersion = createGlobalStyle`
  html,body {
    background: ${theme.color.gray};
    justify-content: center;
    position: 'relative';
    align-items: center;
    min-height: 100%;
    padding: 5rem 0;
    display: flex;
    width: 100%;
  }

  #root {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    background: ${theme.color.white};
    border-radius: 0.25rem;
    min-width: 45rem;
    overflow: hidden;
    min-height: 60%;
    height: 60%;
    width: 70%;
    max-width: 70rem;
  }
`;
