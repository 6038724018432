import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'regenerator-runtime/runtime';

import 'app/assets/app.scss';
import 'app/assets/fonts.scss';
import 'app/assets/icomoon/style.css';

import prepareStore from 'app/store/prepareStore';
import ErrorBoundary from 'core/components/ErrorBoundary';
import { INITIAL_URL_KEY } from 'core/effects/logout';
import extendYup from 'core/extendYup';
import history from 'core/history';
import i18nInit from 'core/i18n';
import 'core/polyfills';

import App from './app/components/App';

// store initial route before its touched with react router
// used for APP redirect (core/effects/logout.ts)
localStorage.setItem(INITIAL_URL_KEY, window.location.href);

const { store, persistor } = prepareStore(history);

window.getReduxState = () => store.getState();

const i18n = i18nInit(store);
extendYup();

const root = createRoot(document.getElementById('root')!);

root.render(
  <I18nextProvider i18n={i18n as any}>
    <ErrorBoundary fullScreen={true} includeReload={true}>
      <App store={store} history={history} persistor={persistor} />
    </ErrorBoundary>
  </I18nextProvider>
);
