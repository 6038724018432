import { DateTime } from 'luxon';
import { useCallback } from 'react';

import useLabelsInputMapping from 'common/components/Labels/useLabelsInputMapping';
import { OTHER_NUMBER } from 'core/components/SelectOtherField';
import { transformDateTimeToDate } from 'core/effects/apiCall/dateTimeTransformations';
import useAppSelector from 'core/hooks/useAppSelector';
import useClientOption, { useBoolClientOption } from 'core/hooks/useClientOption';
import useAnalysesInputMapping from 'planning/components/AnalysesFields/analysesInputMapping';
import useOfficersInputMapping from 'planning/components/OfficersFieldset/useOfficersInputMapping';
import useSettingsInputMapping from 'planning/components/SettingsTab/useSettingsInputMapping';
import useTeamInputMapping from 'planning/components/TeamTab/useTeamInputMapping';
import Test from 'planning/models/Test';

import useTestSchema, { TestFormData } from './useTestSchema';

const useTestInputMapping = (
  mode: 'edit' | 'clone' | 'create',
  disableAdvancedFeatures: boolean = false
) => {
  const schema = useTestSchema({ disableAdvancedFeatures });
  const analysesInputMapping = useAnalysesInputMapping();
  const teamInputMapping = useTeamInputMapping(mode);
  const officersInputMapping = useOfficersInputMapping(mode);
  const defaultSampleCollectionAuthority = useClientOption('defaultSampleCollectionAuthority');
  const defaultResultAuthority = useClientOption('defaultResultAuthority');
  const defaultTestAuthority = useClientOption('defaultTestAuthority');
  const defaultFederationsId = useClientOption('defaultFederationsId');
  const defaultDebtorsId = useClientOption('defaultDebtorsId');
  const defaultCustomersId = useClientOption('defaultCustomersId');
  const defaultInitiatorAuthority = useClientOption('defaultForDcc');
  const defaultEventName = useClientOption('defaultForEventName');
  const defaultNotifyOfTeamUpdates = useBoolClientOption('defaultForNotifyOfTeamUpdates');
  const defaultShowAthleteToDco = useBoolClientOption('defaultForShowAthleteToDco');
  const defaultShowInternalCommentToDco = useBoolClientOption('defaultForShowInternalCommentToDco');
  const defaultForUse3genApp = useBoolClientOption('defaultForUse3genApp');
  const defaultForAllowIcPoolCollection = useBoolClientOption('defaultForAllowIcPoolCollection');
  const userTimeZonesId = useAppSelector(({ core }) => core.user?.timeZonesId);
  const settingsInputMapping = useSettingsInputMapping();
  const labelsInputMapping = useLabelsInputMapping();

  return useCallback(
    (test?: Test & { event?: { name?: string } }): TestFormData => {
      const analysesMapping = analysesInputMapping(test || ({} as Test));

      const { dateFrom, dateTo, ...testData } = transformDateTimeToDate(test || ({} as Test));

      if (test) {
        return schema.cast(
          {
            ...testData,
            ...analysesMapping,
            athletesId: test.athlete ? test.athlete.id : test.athletesId || null,
            dateRange:
              dateFrom && dateTo
                ? {
                    from: dateFrom,
                    to: dateTo,
                  }
                : null,
            timeFrom: testData.timeFrom && DateTime.fromISO(testData.timeFrom).toJSDate(),
            timeTo: testData.timeTo && DateTime.fromISO(testData.timeTo).toJSDate(),
            externalId: mode === 'create' ? null : testData.externalId,
            federationsId: testData.federationsName ? OTHER_NUMBER : testData.federationsId,
            customersId: testData.customersName ? OTHER_NUMBER : testData.customersId,
            debtorsId: testData.debtorsName ? OTHER_NUMBER : testData.debtorsId,
            // @ts-ignore, when mission is passed in, it has a event subobject with a name property
            eventName: testData.eventName || testData.event?.name || defaultEventName,
            ...labelsInputMapping(test.labels || []),
            ...analysesMapping,
            // TODO remove redundant mapping business logic https://cannypack.atlassian.net/browse/MODFE-3624
            ...teamInputMapping(testData),
            ...officersInputMapping(testData),
            ...settingsInputMapping(testData),
          },
          {
            stripUnknown: true,
          }
        );
      }

      return schema.cast(
        {
          timeZonesId: userTimeZonesId,
          eventName: defaultEventName,
          initiatorAuthoritiesId: defaultInitiatorAuthority,
          sampleAuthoritiesId: defaultSampleCollectionAuthority,
          resultAuthority: defaultResultAuthority,
          testAuthority: defaultTestAuthority,
          debtorsId: defaultDebtorsId,
          customersId: defaultCustomersId,
          federationsId: defaultFederationsId,
          notifyOfTeamUpdates: defaultNotifyOfTeamUpdates,
          showAthleteToDco: defaultShowAthleteToDco,
          showInternalCommentToDco: defaultShowInternalCommentToDco,
          allowIcPoolCollection: defaultForAllowIcPoolCollection,
          use3genApp: defaultForUse3genApp,
        },
        {
          stripUnknown: true,
        }
      );
    },
    [
      analysesInputMapping,
      schema,
      mode,
      teamInputMapping,
      officersInputMapping,
      settingsInputMapping,
      userTimeZonesId,
      defaultEventName,
      defaultInitiatorAuthority,
      defaultSampleCollectionAuthority,
      defaultResultAuthority,
      defaultTestAuthority,
      defaultFederationsId,
      defaultNotifyOfTeamUpdates,
      defaultShowAthleteToDco,
      defaultShowInternalCommentToDco,
      defaultForAllowIcPoolCollection,
      defaultForUse3genApp,
      defaultDebtorsId,
      defaultCustomersId,
      labelsInputMapping,
    ]
  );
};

export default useTestInputMapping;
