import { PropsWithChildren } from 'react';
import styled from 'styled-components'; //macro

import theme from 'app/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: calc(100% - 5.8em);
  top: 5.8em;
  left: 0;
  overflow-y: auto;
  background: ${theme.color.white};
  z-index: 9;
  text-align: center;
`;

interface StyledYearProps extends PropsWithChildren {
  onClick?: () => void;
  className?: string;
  tabIndex?: number;
  chosen?: boolean;
}

/** Styled span elements selectable via [data-chosen="chosen"] selector */
export const StyledYearOption = styled(
  ({ tabIndex, onClick, children, className, chosen }: StyledYearProps) => (
    <span
      data-chosen={chosen ? 'chosen' : null} // So that we can query the element with procedural code
      data-cy="StyledYearOption"
      className={className}
      tabIndex={tabIndex}
      onClick={onClick}
    >
      {children}
    </span>
  )
)`
  display: block;
  padding: 0.5em;
  cursor: pointer;

  font-size: ${(p) => (p.chosen ? '1.2em' : 'inherit')};
  color: ${(p) => (p.chosen ? theme.color.primary : 'inherit')};
  font-weight: ${(p) => (p.chosen ? 'bold' : 'inherit')};
`;
