import styled from 'styled-components'; //macro

import theme from 'app/theme';

export const PageContent = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  display: flex;
  flex-grow: 1;

  background-color: ${theme.color.gray};

  @media print {
    height: auto;
  }
`;

export const PageBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 4.5rem);

  @media print {
    height: auto;
  }
`;
