import styled from 'styled-components'; //macro

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;

  > .display-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > .material-icons {
    overflow: visible;
    cursor: pointer;
  }
`;
