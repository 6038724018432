import { darken } from 'polished';
import styled, { keyframes } from 'styled-components'; //macro

import theme from 'app/theme';

export type AlertType = 'success' | 'info' | 'warning' | 'error';

const animation = keyframes`
  0% {
    min-height: 0px;
    padding: 0rem;
  }
  100% {
    min-height: 55px;
    padding: 1rem;
  }
`;

export const StyledAlert = styled.div<{ type: AlertType }>`
  width: 100%;
  min-height: 55px;
  display: flex;
  align-items: center;
  padding: 1rem;
  column-gap: 1rem;
  background-color: ${({ type }) => theme.color[`${type}Background`]};
  border-bottom: 5px solid ${({ type }) => theme.color[type]};
  border-radius: 4px;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  animation: ease;
  animation-name: ${animation};
  animation-duration: 0.45s;

  .material-icons {
    width: 1.5rem;
    height: 1.75rem;
    overflow: inherit;
    color: ${({ type }) => theme.color[type]};
  }

  .alert-text {
    color: ${({ type }) => darken(0.32, theme.color[type])};
    text-shadow: 1px 1px 1px ${({ type }) => theme.color[`${type}Background`]};
  }
`;
