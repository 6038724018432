import { FC } from 'react';
import styled, { AnyStyledComponent, css } from 'styled-components'; //macro

import theme from 'app/theme';
import { Grid } from 'core/components/Grid';

import Button, { ButtonProps } from '../Button';

export const PageWrap = styled.div`
  justify-content: space-between;
  align-items: center;
  min-height: 35rem;
  display: flex;
  height: 100vh;
  width: 100%;
  & > div:only-child {
    margin: 0 auto;
  }
`;

export const ContentContainer = styled.div`
  padding: 3rem 0;
  width: 25%;
  min-width: 300px;
  max-width: 450px;
`;

export const StyledText = styled.span`
  color: var(--mdc-theme-secondary);
  font-weight: 300;
  font-size: 0.875rem;
`;

export const StyledSubtitle = styled(StyledText)`
  display: block;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  text-align: center;
`;

export const Actions = styled.div`
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledLink = styled.a<any>`
  color: var(--mdc-theme-primary);
  background: transparent;
  font-size: 0.875rem;
  border: 0;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  margin-top: 2.5rem;

  &:hover {
    text-decoration: none;
    color: ${theme.color.primary700};
  }

  & span {
    width: 20px;
    position: absolute;
    left: -25px;
    animation: 1s alternate forwards reverse;
    transition: 250ms;
  }

  &:hover span {
    transform: translateX(-10px);
  }
`;

export const ContentGrid = styled(Grid)`
  padding: 0;
`;

export const PrimaryButton = styled(Button as AnyStyledComponent)<ButtonProps>`
  box-shadow: none !important;
  width: 100%;
  text-decoration: none;
  height: 2.5rem;
  &:hover {
    text-decoration: none;
  }
` as FC<ButtonProps>;

export const StyledHeader = styled.h2`
  color: var(--mdc-theme-secondary);
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
`;

export const LogoWrap = styled.div<{ dark?: boolean }>`
  margin: 2rem;
  text-align: center;

  svg {
    height: 2rem;
    max-width: 100%;
  }

  ${({ dark }) =>
    dark &&
    css`
      --logo-color: var(--logo-dark-color);
    `}
`;

export const IllustrationWrapper = styled.div`
  width: clamp(200px, 30vw, 20%);
`;
