import styled from 'styled-components'; //macro

export const Dot = styled.div<{ color: string }>`
  height: 0.75rem;
  width: 0.75rem;
  min-height: 0.75rem;
  min-width: 0.75rem;
  border-radius: 50%;
  background-color: ${(p) => `${p.color} !important`};
`;
