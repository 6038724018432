import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';

export const TableWrapper = styled.div`
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
`;

export const TableRow = styled.tr`
  td,
  th {
    padding: 1rem;
  }

  th {
    font-size: 0.75rem;
    color: ${theme.color.gray400};
  }

  td {
    font-size: 0.875rem;
  }

  th,
  &:not(:last-of-type) td {
    border-bottom: 0.15rem solid ${theme.color.gray100};
  }
`;

const stickyCell = css`
  position: sticky;
  z-index: 1;
  :first-of-type {
    border-right: 0.15rem solid ${theme.color.gray100};
    left: 0px;
  }
  :last-of-type {
    border-left: 0.15rem solid ${theme.color.gray100};
    right: 0px;
  }

  /* Our IconButton is in px */
  width: 48px;
  padding: 0 !important;
  background: ${theme.color.white};
`;

export const StickyHeaderActionCell = styled.th`
  ${stickyCell}
`;

export const StickyActionCell = styled.td`
  ${stickyCell}
`;

export const NoItemsPlaceholder = styled.em`
  display: block;
  margin: 1rem;
`;
