import styled, { css } from 'styled-components'; //macro

const AgGridPaperWrapper = styled.div.attrs({ className: 'ag-theme-material' })<{
  customHeight?: number;
  fullHeight?: boolean;
}>`
  ${(p) => {
    return p.fullHeight
      ? css`
          height: 100%;
          min-height: ${p.customHeight || 15}rem;
        `
      : css`
          height: ${p.customHeight || 15}rem;
        `;
  }}

  .ag-react-container {
    height: 100%;
  }

  .ag-cell {
    display: flex;
    align-items: center;
  }

  .ag-cell-wrapper {
    width: 100%;
  }
`;

export default AgGridPaperWrapper;
