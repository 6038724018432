import styled from 'styled-components'; //macro

import theme from 'app/theme';

export const Wrap = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  height: 100%;

  & > hr:not(:last-child) {
    width: 1px;
    border: none;
    height: 100%;
    display: flex;
    margin: 0;
    background: ${theme.color.gray100};
  }
`;
