import styled from 'styled-components'; //macro

import { FlexCell } from 'core/components/FlexUtils';

export const Content = styled.div<{ marginTop: boolean }>`
  overflow-x: auto;
  margin: 0 1rem 1rem 1rem;
  /* Accounts for floating field labels */
  padding-top: 0.35rem;
  ${({ marginTop }) => marginTop && 'margin-top: 1rem;'}
`;

export const ActionFlexCell = styled(FlexCell)`
  /* In px because material design components text field is in px */
  padding-top: 4px;
`;
