import styled from 'styled-components'; //macro

import theme from 'app/theme';

export const StyledDivider = styled.div<{
  $orientation?: string;
  $backgroundColor?: string;
  $marginVertical?: string;
}>`
  background-color: ${(props) =>
    props.$backgroundColor ? props.$backgroundColor : theme.color.baseFontColor};
  min-width: ${(props) => (props.$orientation === 'horizontal' ? '100%' : '1px')};
  min-height: ${(props) => (props.$orientation === 'vertical' ? '100%' : '1px')};
  align-self: stretch;
  margin: ${(props) => (props.$marginVertical ? `${props.$marginVertical} 0` : '0rem')};
`;
