import styled from 'styled-components'; //macro

const StyledRadio = styled.div`
  display: inline-flex;

  & > .mdc-form-field > label {
    margin: 0;
  }
`;

const RadioInput = styled.div`
  display: flex;

  & > * {
    display: inline-flex;
    padding-right: 0.5rem;
    margin: 0 0 0.5rem 0;
    text-align: center;
    align-items: center;
  }
`;

export { RadioInput, StyledRadio };
