import styled, { css } from 'styled-components'; //macro

export const FlexRow = styled.div.attrs((props: any) => ({
  'data-cy': props['data-cy'] || 'FlexRow',
}))<{
  verticalAlign?: string;
  horizontalAlign?: string;
  spacing?: string;
  padding?: string;
  height?: string;
  marginTop?: string;
  fullWidth?: boolean;
}>`
  margin-bottom: ${(p) => p.spacing || '1rem'};
  align-items: ${(p) => p.verticalAlign || 'center'};
  justify-content: ${(p) => p.horizontalAlign || 'normal'};
  flex-direction: row;
  display: flex;
  flex: 1;
  padding: ${(p) => p.padding || '0rem'};

  ${(p) =>
    p.height &&
    css`
      height: ${p.height};
    `}

  ${(p) =>
    p.marginTop &&
    css`
      margin-top: ${p.marginTop};
    `}

    ${(p) =>
    p.fullWidth &&
    css`
      width: 100%;
    `}


  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const FlexColumn = styled.div.attrs((props: any) => ({
  'data-cy': props['data-cy'] || 'FlexColumn',
}))<{
  verticalAlign?: string;
  horizontalAlign?: string;
  spacing?: string;
  padding?: string;
  height?: string;
  marginTop?: string;
  fullWidth?: boolean;
  width?: string;
  marginRight?: string;
  marginLeft?: string;
  alignSelf?: string;
}>`
  margin-bottom: ${(p) => p.spacing || '1rem'};
  margin-right: ${(p) => p.marginRight || '0rem'};
  margin-left: ${(p) => p.marginLeft || '0rem'};
  align-items: ${(p) => p.verticalAlign || 'center'};
  justify-content: ${(p) => p.horizontalAlign || 'normal'};
  flex-direction: column;
  display: flex;
  flex: 1 1 0;
  padding: ${(p) => p.padding || '0rem'};
  align-self: ${(p) => p.alignSelf && p.alignSelf};

  ${(p) =>
    p.height &&
    css`
      height: ${p.height};
    `}
  ${(p) =>
    p.width &&
    css`
      width: ${p.width};
    `}

  ${(p) =>
    p.fullWidth &&
    css`
      width: 100%;
    `}

  ${(p) =>
    p.marginTop &&
    css`
      margin-top: ${p.marginTop};
    `}

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const MinWidthFlexRow = styled(FlexRow)`
  min-width: ${(p: { minWidth: string }) => p.minWidth};
`;

export const FlexCell = styled.div.attrs(({ className }) => ({ className }))<{
  flex?: number;
  block?: boolean;
  maxWidth?: string;
  minWidth?: string;
  alignSelf?: string;
  direction?: string;
  marginLeft?: number;
}>`
  display: ${(p) => (p.block ? 'block' : 'flex')};
  flex-direction: ${(p) => (p.direction ? p.direction : 'column')};
  align-items: center;
  align-self: ${(p) => p.alignSelf || undefined};
  justify-content: center;

  margin: 0 0.5rem;
  margin-left: ${(p) => p.marginLeft || 0}rem;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  min-width: ${(p) => p.minWidth || '1rem'};
  max-width: ${(p) => p.maxWidth || 'auto'};

  ${(p) =>
    p.flex &&
    css`
      flex: ${p.flex};
    `}
`;
