import styled from 'styled-components'; //macro

export const TooltipWrapper = styled.div<{ width?: string }>`
  width: ${(p) => `${p.width ? p.width : '100%'}`};
  height: 100%;
  & > span {
    display: flex;
    max-width: 100%;
    width: fit-content;
    align-items: center;

    & .material-icons {
      flex-shrink: 0;
      height: fit-content;
      max-width: 22px;
    }
  }
`;
