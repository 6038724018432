import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components'; //macro

export const IconWrapper = styled.div.attrs(({ className, children }) => ({
  className,
  children,
}))<{ color?: string; dense?: boolean }>`
  .mdc-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ color }) => color};

    /* Our default ripple size, defined for fixing empty ripple on some places */
    --mdc-ripple-fg-size: 28px !important;
    --mdc-ripple-fg-scale: 1.71429 !important;
    --mdc-ripple-left: 10px !important;
    --mdc-ripple-top: 10px !important;

    ${(p: { dense?: boolean }) =>
      p.dense &&
      css`
        &.mdc-ripple-upgraded {
          &.mdc-ripple-upgraded--unbounded {
            --mdc-ripple-fg-size: 20px !important;
            --mdc-ripple-fg-scale: 1.71429 !important;
            --mdc-ripple-left: 14px !important;
            --mdc-ripple-top: 14px !important;
            width: 48px;
          }
        }
      `};
  }
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const StyledLink = styled(Link)`
  color: var(--mdc-theme-secondary);
  &:hover {
    text-decoration: none;
    color: var(--mdc-theme-secondary);
  }
`;

export const Badge = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  width: 25%;
  height: 25%;
`;
