import styled from 'styled-components'; //macro

export const Item = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: auto;
  height: 300px;
  padding: 5rem;
`;

export const Img = styled.div`
  height: 5rem;
  width: 5rem;
`;

export const Text = styled.span`
  margin-top: 1rem;
`;
