import styled from 'styled-components'; //macro

import theme from 'app/theme';
import IconButton from 'core/components/IconButton';

export const Title = styled.h2`
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
`;

export const Header = styled.div<{ verticalPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(p) => (p.verticalPadding !== false ? '1rem' : '0.25rem 1rem')};

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const SelectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 0.25rem 1rem;

  font-size: 0.875rem;
  color: var(--mdc-theme-primary);
  background: ${theme.color.primary50};
`;

export const CheckboxIconButton = styled(IconButton)`
  color: ${theme.color.gray400};
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
`;
