import Card from '@material/react-card';
import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';
import MaterialIcon from 'core/components/MaterialIcon';

import { hidePrint } from '../PrintOnly/styled';

export const Headline = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  width: 100%;
`;

export const ToggleContainer = styled(Headline)`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const FirstLevelTitle = styled.h3<{ $hasError?: boolean; $fullWidth?: boolean }>`
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 1rem;
  display: flex;
  width: ${(p) => (p.$fullWidth ? '100%' : 'auto')};
  margin-top: 0;
  color: ${(p) => (p.$hasError ? theme.color.error : 'inherit')};

  & > .material-icons {
    margin-right: 0.5rem;
  }
`;

export const CardInnerSection = styled.div<{ $fullHeight?: boolean }>`
  flex-direction: column;
  display: flex;
  padding: 1rem;
  width: 100%;

  ${(p) =>
    p.$fullHeight &&
    css`
      height: 100%;
    `};

  & & ${FirstLevelTitle} {
    text-transform: none;
  }
`;

export const Content = styled.div<{ $paddingTop?: boolean; $fullHeight?: boolean }>`
  display: block;
  flex: 1;

  ${(p) =>
    p.$fullHeight &&
    css`
      height: 100%;
    `};

  ${(p) =>
    p.$paddingTop &&
    css`
      padding-top: 1.35rem;
    `};
`;

export const BadgePrimary = styled.div`
  display: inline-block;
  margin-left: 1rem;
  background-color: var(--mdc-theme-primary);
  border-radius: 1.5625rem;
  color: ${theme.color.white};
  font-size: 0.6875rem;
  line-height: 1rem;
  width: 2rem;
  text-align: center;
  height: 1rem;
  ${(p) =>
    p.color &&
    css`
      background-color: ${p.color};
    `}
`;

export const CollapseIconWrapper = styled.div<{ $right?: boolean }>`
  display: inline-block;
  padding-left: 1rem;

  ${(props) =>
    props.$right &&
    css`
      margin-left: auto;
    `}
`;

export const ToggleTitle = styled(FirstLevelTitle)`
  margin-bottom: 0;
`;

export const IconsContainer = styled.div`
  display: flex;
  margin-left: auto;
  ${hidePrint}
`;

export const StyledCard = styled(Card)<{
  $overflowX?: string;
  $overflowY?: string;
  $maxWidth?: string;
  $maxHeight?: string;
  $marginBottom?: string;
}>`
  overflow-x: ${({ $overflowX }) => $overflowX};
  overflow-y: ${({ $overflowY }) => $overflowY};
  max-width: ${({ $maxWidth }) => $maxWidth};

  ${(props) =>
    props.$maxHeight &&
    css`
      max-height: ${props.$maxHeight};
    `}

  ${(props) =>
    props.$marginBottom &&
    css`
      margin-bottom: ${props.$marginBottom};
    `}

  &.full-height-card {
    height: 100%;
  }
`;

export const InfoIcon = styled(MaterialIcon).attrs({ icon: 'info_outline' })`
  margin-left: 0.5rem;
  size: 0.5rem;
`;
