import styled from 'styled-components'; //macro

import theme from 'app/theme';
import { Row } from 'core/components/Grid';

export const StyledRow = styled(Row)`
  label {
    color: ${theme.color.baseFontColor};
  }
`;
