import styled from 'styled-components'; //macro

import theme from 'app/theme';

export const GridWrapper = styled.div<{ toolbarHeight?: string }>`
  width: 100%;
  height: calc(100% - ${(props) => props.toolbarHeight || '3rem'});
`;

export const AgGridWrapper = styled.div`
  height: 100%;
  position: relative;
  .ag-row-odd {
    background: ${theme.color.gray};
  }

  .ag-root .ag-row-highlighted {
    background-color: ${theme.color.warningBackground};
  }

  .ag-cell {
    display: flex;
    align-items: center;

    .ag-react-container {
      min-width: 100%;
    }
  }

  .ag-header-cell.ag-header-cell-sortable {
    .ag-cell-label-container {
      height: 100%;
    }
  }

  /* If we don't force displaying of scrollbar, ag-grid won't calculate width correctly before load */
  .ag-body-viewport.ag-layout-normal {
    overflow-y: scroll;
  }

  .ag-icon.ag-icon-checkbox-checked {
    color: var(--mdc-theme-secondary);
  }

  .ag-cell-wrapper {
    width: 100%;
  }
`;

export const GridOverlayContainer = styled.div`
  position: absolute;
  /* AG Grid uses pixels */
  top: 56px;
  z-index: 6;
  width: 100%;
`;

export const StyledIconWrapper = styled.div`
  margin-left: 0.25rem;
`;

export const BadgeIconWrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

export const RightTextWrapper = styled.div`
  margin-left: 0.3rem;
`;

export const HeightLimitedDatagridWrapper = styled.div`
  height: 30rem;
`;
