import getEnv from 'core/functions/getEnv';
import { useEffect } from 'react';

let isWebkit = false; // check if browser supports webkit selector
try {
  document.querySelector('input:-webkit-autofill');
  isWebkit = getEnv('NODE_ENV') !== 'test' && true;
} catch (e) {
  isWebkit = false;
}

const useMDCAutofillHackEffect = () => {
  useEffect(() => {
    if (isWebkit) {
      const i = setInterval(() => {
        const e = document.querySelector('input:-webkit-autofill') as unknown as
          | null
          | HTMLInputElement[];
        if (e) {
          clearInterval(i);
          setTimeout(() => {
            const wrappers = document.getElementsByClassName('mdc-notched-outline--upgraded');
            for (const i of Array.from(wrappers)) {
              i.className += ' mdc-notched-outline--notched';
            }

            const labels = document.getElementsByClassName('mdc-floating-label');
            for (const i of Array.from(labels)) {
              i.className += ' mdc-floating-label--float-above';
            }
          }, 125);
        }
      }, 125);

      // stop interval after some time if there was nothing found
      setTimeout(() => {
        clearInterval(i);
      }, 3000);

      return () => {
        clearInterval(i);
      };
    }
  }, []);
};

export default useMDCAutofillHackEffect;
