import { HelperText } from '@material/react-text-field';
import styled from 'styled-components'; //macro

import theme from 'app/theme';
import IconButton from 'core/components/IconButton';

export const StyledHelperText = styled(HelperText)`
  font-size: 0.75rem;
  margin-left: 0.375rem;
  padding-top: 0.2rem;
  opacity: 1;
  letter-spacing: normal;
`;

export const StyledIconButton = styled(IconButton)`
  border: 1px solid ${theme.color.gray300};
  border-radius: 50%;
`;
