import { useFormikContext } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FileEntityKey, getFileEntityName } from 'app/hooks/useFileEntityLookups';
import Button from 'core/components/Button';
import { Option } from 'core/components/DropDown';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import Field from 'core/components/Form/Field';
import FormDropDown from 'core/components/FormDropDown';
import FormFileInput from 'core/components/FormFileInput';
import FormTextfield from 'core/components/FormTextfield';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import StyledButtonWrapper from 'core/components/StyledButtonWrapper';
import { FormData as FileAttachmentFormData } from 'core/containers/AddFilesDialog/useSchema';
import RestrictedVisibility from 'core/containers/Comments/Comment/CommentForm/RestrictedVisibility';
import useAppSelector from 'core/hooks/useAppSelector';
import { useResources } from 'core/hooks/useResource';
import FileType from 'core/models/FileType';

import { ContentWrapper } from '../styled';
import {
  useCanRestrictVisibility,
  useHasUnrestrictedVisibility,
} from '../useRestrictVisibilityPermissions';

import EntityFields from './EntityFields';

interface OwnProps {
  onCancel: () => void;
  fileEntity?: FileEntityKey;
  requestInProgress: boolean;
  unrestrictedRoleIds?: number[];
}

const Fieldset: FC<OwnProps> = ({
  onCancel,
  fileEntity,
  requestInProgress,
  unrestrictedRoleIds,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue, setValues, resetForm } =
    useFormikContext<FileAttachmentFormData>();
  const canRestrictVisibility = useCanRestrictVisibility(fileEntity || 'file', 'create');
  const hasUnrestrictedVisibility = useHasUnrestrictedVisibility(fileEntity || 'file');
  const currentUsersRole = useAppSelector(({ core }) => core.user?.rolesId);

  const onFileTypeChange = useCallback(
    (val: number | null, fileType: Option & { extra: FileType }) => {
      setValues({
        ...values,
        fileTypesId: val,
        entityId: !fileEntity ? null : values.entityId,
        restrictToRoles: fileType?.extra?.restrictToRoles || [],
      });
    },
    [setValues, values, fileEntity]
  );

  const onRoleRestrictionChange = (val: number[] | null) => {
    setFieldValue('restrictToRoles', val);
  };

  const { data: fileTypes } = useResources<FileType>('files/types');
  const fileEntityName = fileEntity && getFileEntityName(fileEntity);

  const fileTypesOptions = useMemo(() => {
    if (!fileTypes) {
      return [];
    }

    let allowedFileTypes = fileTypes;
    if (!hasUnrestrictedVisibility) {
      // filter by allowed file types
      allowedFileTypes = fileTypes.filter(
        (type) =>
          !type.restrictToRoles?.length ||
          (currentUsersRole && type.restrictToRoles.includes(currentUsersRole))
      );
    }

    if (fileEntityName) {
      allowedFileTypes = allowedFileTypes.filter((type) => type.entities.includes(fileEntityName));
    }
    // filter by restrictToRoles
    return allowedFileTypes.map((x) => ({
      id: x.id,
      name: x.name,
      extra: x,
    }));
  }, [fileTypes, fileEntityName, currentUsersRole, hasUnrestrictedVisibility]);

  const chosenFileType = useMemo(
    () => fileTypes && fileTypes.find((fileType) => fileType.id === values.fileTypesId),
    [values.fileTypesId, fileTypes]
  );

  return (
    <>
      <ContentWrapper>
        <Row>
          <FullRowCell>
            <FlexRow>
              <FlexCell flex={1}>
                <Field
                  single
                  options={fileTypesOptions || []}
                  onChange={onFileTypeChange}
                  component={FormDropDown}
                  label={t('File Type')}
                  name="fileTypesId"
                  fast={false}
                  disabled={requestInProgress}
                />
              </FlexCell>

              {canRestrictVisibility && (
                <FlexCell>
                  <RestrictedVisibility
                    restrictToRoles={(values.restrictToRoles || []) as number[]}
                    onChangeRoles={onRoleRestrictionChange}
                    unrestrictedRoleIds={unrestrictedRoleIds}
                    title={t('Choose which user roles can see this file')}
                  />
                </FlexCell>
              )}
            </FlexRow>
          </FullRowCell>

          {!fileEntity && values.fileTypesId && chosenFileType && (
            <EntityFields
              onEntityChange={() => !fileEntity && setFieldValue('entityId', null)}
              fileEntity={fileEntity}
              fileType={chosenFileType}
            />
          )}

          <FullRowCell>
            <Field
              component={FormTextfield}
              label={t('File Description')}
              name="description"
              disabled={requestInProgress}
              textarea
              rows={3}
            />
          </FullRowCell>

          <FullRowCell>
            <Field
              fast={false}
              multiple
              disabled={requestInProgress}
              component={FormFileInput}
              name="files"
              onChange={(files: File[]) =>
                setFieldValue('files', [...(values.files || []), ...files])
              }
            />
          </FullRowCell>
        </Row>
      </ContentWrapper>

      <FullRowCell>
        <StyledButtonWrapper>
          <Button onClick={() => resetForm()} text={t('Clear')} type="button" />
          <div>
            <Button onClick={() => onCancel()} text={t('Cancel')} type="button" />
            <Button type="submit" disabled={requestInProgress} text={t('Save')} />
          </div>
        </StyledButtonWrapper>
      </FullRowCell>
    </>
  );
};

export default Fieldset;
