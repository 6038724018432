import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';

export const StyledRadio = styled.div<{
  $checked?: boolean;
  $disabled?: boolean;
}>`
  & input {
    display: none;
  }

  label::after {
    content: '';
    display: inline-block;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    border: 2px solid ${theme.color.black};
    position: absolute;
    right: 1rem;
    top: 18px;
    border-radius: 50%;

    ${(props) =>
      props.$disabled &&
      css`
        display: none;
      `}
  }

  input:checked + label::after {
    background-color: ${theme.color.primary};
    background-size: cover;
    border: 2px solid ${theme.color.gray};
    outline: 2px solid ${theme.color.primary};
  }

  label {
    background: ${({ $checked }) => ($checked ? theme.color.primary5 : theme.color.gray)};
    border: ${({ $checked }) =>
      $checked ? `1.5px solid ${theme.color.primary}` : `1.5px solid ${theme.color.white}`};
    border-radius: 12px;
    height: 56px;
    padding: 0.5rem 1rem;
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${(props) =>
      props.$disabled &&
      css`
        cursor: not-allowed;
      `}
  }

  width: 100%;
  display: flex;
  justify-content: space-between;
`;
