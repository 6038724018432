import styled from 'styled-components'; //macro

import theme from 'app/theme';
import MaterialIcon from 'core/components/MaterialIcon';

export const StyledTabIcon = styled(MaterialIcon)<{
  color?: keyof typeof theme.color;
}>`
  color: ${(p) => (p.color ? theme.color[p.color] : 'inherit')};
  margin: 0 0.5rem;
  font-size: 1rem;
  pointer-events: auto;
  z-index: 1;
`;
