import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { entities } from 'app/entity';
import { AlertProvider } from 'core/components/Alert';
import RenderTabs from 'core/components/DetailSubheader/Tabs/RenderTabs';
import Grid, { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import { ChildrenProps, DetailModeEnum } from 'core/containers/FormDetailPage';
import EntityDetailPage from 'core/containers/FormDetailPage/EntityDetailPage';
import Watchers from 'core/containers/Watchers';
import { useSetModal } from 'core/hooks/useModal';
import usePermission from 'core/hooks/usePermission';
import { editMission } from 'planning/actions';
import ConfirmUndoStatementModal from 'planning/components/ConfirmUndoStatementModal';
import InvitationStatementModal from 'planning/components/InvitationStatementModal';
import { TeamMemberEntities } from 'planning/enums';
import Mission, { MissionStatus } from 'planning/models/Mission';

import MissionDetailSubheader from './components/MissionDetailSubheader';
import useGetMissionMainControls from './hooks/useGetMissionMainControls';
import useGetSecondaryControls from './hooks/useGetMissionSecondaryControls';
import useMissionInputMapping from './hooks/useMissionInputMapping';
import { MissionModal, useMissionModal } from './hooks/useMissionModal';
import useOutputMapping, { MissionRequestModel } from './hooks/useMissionOutputMapping';
import { MissionFormData } from './hooks/useMissionSchema';
import useMissionTabs from './hooks/useMissionTabs';
import MissionForm from './MissionForm';
import AppliedEmailsDialog from './modals/AppliedEmailsDialog';
import BulkCreateTestsModal from './modals/BulkCreateTestsModal';
import CreateInvoiceDialog from './modals/CreateInvoiceDialog';
import CreateTestByGenderModal from './modals/CreateTestsByGenderModal';

// list of disabled changes which should not be reflected when mission  (paths in diff)
export const DISALLOWED_TEST_CHANGE_FIELDS: string[] = ['externalId', 'code', 'use3genApp'];

interface Props {
  mode: DetailModeEnum;
}

const MissionDetailPage: FC<Props> = ({ mode }) => {
  const getMissionMainControls = useGetMissionMainControls();
  const secondaryControls = useGetSecondaryControls(mode);
  const { params } = useRouteMatch<{ id?: string }>();
  const outputMapping = useOutputMapping();
  const { t } = useTranslation();
  const watchersOpen = useMissionModal(MissionModal.WATCHERS);
  const setModal = useSetModal();

  const missionsId = Number(params.id) || null;

  // TODO: create re-fetch action to load specific resource when new tests are created

  const missionInputMapping = useMissionInputMapping(mode);
  const canCreateMission = usePermission('missions:create');
  const tabs = useMissionTabs(mode);
  const dispatch = useDispatch();
  const isEdit = mode === 'edit';

  const getDefaultUrl = useCallback(
    ({ entityData }: ChildrenProps<Mission>) => {
      const url =
        canCreateMission &&
        entityData?.status &&
        isEdit &&
        [MissionStatus.OPEN, MissionStatus.CLOSED].includes(entityData.status)
          ? entities.mission.urls().detailContents(entityData?.id)
          : undefined;

      return url;
    },
    [canCreateMission, isEdit]
  );

  return (
    <EntityDetailPage<'mission', MissionFormData, MissionRequestModel>
      title={(entityData) => (!isEdit ? t('New Mission') : (entityData && entityData.code) || '')}
      secondaryControls={secondaryControls}
      mainControls={getMissionMainControls}
      id={Number(params.id) || undefined}
      inputMapping={missionInputMapping}
      outputMapping={outputMapping}
      displaySubheader={false}
      urlsContext={undefined}
      apiContext={undefined}
      entity="mission"
      mode={mode}
      includeResourceMapping={{
        bloodAnalysesId: 'bloodAnalyses',
        urineAnalysesId: 'urineAnalyses',
        dbsAnalysesId: 'dbsAnalyses',
      }}
    >
      <MissionForm onEdit={(...a) => dispatch(editMission(...a))}>
        <MissionDetailSubheader tabs={tabs} />
        <Grid $fullHeight>
          <Row $fullHeight>
            <AlertProvider defaultType="warning">
              <FullRowCell>
                <RenderTabs<Mission> tabs={tabs} getDefaultUrl={getDefaultUrl} />
              </FullRowCell>
            </AlertProvider>
          </Row>
        </Grid>
      </MissionForm>

      {missionsId && (
        <>
          <AppliedEmailsDialog />
          <InvitationStatementModal entity={TeamMemberEntities.MISSION} id={missionsId} />
          <ConfirmUndoStatementModal entity={TeamMemberEntities.MISSION} id={missionsId} />
          <BulkCreateTestsModal />
          <CreateInvoiceDialog />

          {isEdit && (
            <Watchers
              open={watchersOpen}
              setOpen={() => setModal(undefined)}
              entityId={missionsId}
              entityKey="mission"
            />
          )}
          {isEdit && <CreateTestByGenderModal />}
        </>
      )}
    </EntityDetailPage>
  );
};

export default MissionDetailPage;
