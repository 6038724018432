import styled from 'styled-components'; //macro

export const Badge = styled.div<{ $color?: string }>`
  color: #fff;
  text-align: center;
  padding: 0.25rem 0.5rem;
  display: inline-block;
  border-radius: 1rem;
  font-size: 0.75rem;
  line-height: 1;
  background-color: ${(props) => (props.$color ? props.$color : 'transparent')};
`;
