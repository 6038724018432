import ContentLoader from 'react-content-loader';
import styled from 'styled-components'; //macro

export const Wrapper = styled.div`
  align-items: center;
  padding: 0 1rem;
  display: flex;
  height: 72px;
`;

export const Circle = styled(ContentLoader)`
  border-radius: 100%;
  margin-right: 32px;
  overflow: hidden;
  height: 38px;
  width: 38px;
`;

export const Content = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const Primary = styled(ContentLoader)`
  border-radius: 3px;
  margin-top: 1rem;
  height: 1rem;
  width: 120px;
`;

export const Secondary = styled(ContentLoader)<{ width: number }>`
  width: ${(p) => p.width}%;
  border-radius: 3px;
  margin-top: 0.5rem;
  height: 0.75rem;
`;
