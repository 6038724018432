import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';
import StyledIcon from 'core/components/StyledIcon';

export const activeClassName = 'nav-item-active';

export const activeItemBackground = theme.sidebarActiveBackground || theme.color.secondary800;

export const GroupHeader = styled.div<{ collapse?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${({ collapse }) => (collapse ? '1rem 1.1rem' : '1rem')};
  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  &.${activeClassName} {
    font-weight: bold;

    ${StyledIcon}:first-of-type {
      color: var(--mdc-theme-primary);
    }
  }
`;

export const GroupWrap = styled.div<{ isOpen: boolean }>`
  color: ${theme.color.white};
  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${activeItemBackground};
    `}
`;

export const Heading = styled.div`
  font-size: 0.75rem;
  margin-left: 1rem;
  overflow: hidden;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NavItem = styled.div`
  text-decoration: none;
  text-transform: none;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.375rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.color.white};
`;

export const NavItemWrap = styled(NavLink).attrs({ activeClassName })`
  display: flex;
  align-items: center;
  padding: 1rem 1.625rem 1rem 3.25rem;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;

  &:hover {
    background: ${theme.color.white100};
    text-decoration: none;
  }

  &.${activeClassName} {
    ${NavItem} , ${Heading} {
      color: var(--mdc-theme-primary) !important;
    }
  }
`;

export const StyledLink = styled(NavLink).attrs({ activeClassName })`
  display: block;
  &:hover {
    text-decoration: none;
  }

  &.${activeClassName} {
    background: ${activeItemBackground};
    color: var(--mdc-theme-primary);
  }
`;

export const Collapse = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  height: auto;
  max-height: 600px;
  ${(p) =>
    !p.isOpen &&
    css`
      max-height: 0;
    `}
`;
