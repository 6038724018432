import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DropDown from 'core/components/DropDown';
import IconButton from 'core/components/IconButton';
import Modal from 'core/components/Modal';
import useAppSelector from 'core/hooks/useAppSelector';
import { useResources } from 'core/hooks/useResource';
import { Role } from 'core/models/Role';

type Props = {
  restrictToRoles: number[];
  unrestrictedRoleIds?: number[];
  onChangeRoles?: (newRoleIds: number[]) => void;
  blacklistRoles?: number[];
  title?: string;
};

enum DIALOG_OPEN {
  NONE,
  RESTRICT_VISIBILITY,
}

const RestrictedVisibility: FC<Props> = ({
  restrictToRoles,
  onChangeRoles,
  unrestrictedRoleIds,
  blacklistRoles,
  title,
}) => {
  const [dialogOpen, setDialogOpen] = useState<DIALOG_OPEN>(DIALOG_OPEN.NONE);
  const { t } = useTranslation();
  const modalTitle = title || t('Choose which user roles can see this comment');
  const { data: roles } = useResources<Role>('roles');

  const permissions = useAppSelector(({ core: { user } }) => (user && user.permissions) || {});
  const usersFindPermission = permissions['users:find'];

  const rolesOptions = useMemo(() => {
    const options = (roles || [])
      .filter(
        (role: Role) =>
          (usersFindPermission.params?.roles || []).includes(role.id) &&
          !(unrestrictedRoleIds || []).includes(role.id) &&
          !(blacklistRoles || []).includes(role.id)
      )
      .map((it) => ({ id: it.id, name: it.name }));

    return options;
  }, [roles, usersFindPermission, unrestrictedRoleIds, blacklistRoles]);

  const chosenRoleNames = useMemo(
    () => (roles || []).filter((it) => restrictToRoles.includes(it.id)).map((it) => it.name),
    [roles, restrictToRoles]
  );

  const handleOnChange = useCallback(
    (ids: string | number | (string | number)[] | null) => {
      const allUsers = Array.isArray(ids) && ids.length === roles?.length;
      onChangeRoles && onChangeRoles(allUsers ? [] : (ids as number[]) || []);
    },
    [roles?.length, onChangeRoles]
  );

  return (
    <>
      <IconButton
        onClick={() => setDialogOpen(DIALOG_OPEN.RESTRICT_VISIBILITY)}
        icon={chosenRoleNames.length ? 'lock_outline' : 'lock_open'}
        tooltip={
          chosenRoleNames.length ? chosenRoleNames.join(', ') : t('Visible to all user roles')
        }
        disabled={!onChangeRoles}
      />

      <Modal
        ariaLabel={t('Visible to roles dropdown dialog')}
        title={modalTitle}
        onClose={() => setDialogOpen(DIALOG_OPEN.NONE)}
        open={dialogOpen === DIALOG_OPEN.RESTRICT_VISIBILITY}
        onClear={() => {
          handleOnChange([]);
          setDialogOpen(DIALOG_OPEN.NONE);
        }}
      >
        <DropDown
          options={rolesOptions}
          required={false}
          onChange={handleOnChange}
          mode="inline"
          value={restrictToRoles}
        />
      </Modal>
    </>
  );
};

export default RestrictedVisibility;
