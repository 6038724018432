import styled from 'styled-components'; //macro

import theme from 'app/theme';
import { FlexCell } from 'core/components/FlexUtils';

export const Title = styled.h2`
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  margin-bottom: 0;
  color: ${theme.color.baseFontColor};
  align-self: center;
`;

export const NoMarginCell = styled(FlexCell)`
  margin-left: 0;
  margin-right: 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;

export const StickySettingsWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${theme.color.white};
  margin-bottom: 2rem;
`;
