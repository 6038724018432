import { Form } from 'formik';
import styled from 'styled-components'; //macro

import theme from 'app/theme';

export const Title = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 1.5rem;
`;

export const SplitLine = styled.div`
  justify-content: space-between;
  align-items: flex-end;
  display: flex;

  & > * {
    width: calc(50% - 10px);
  }
`;

export const ButtonWrapper = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
`;

export const ExplanationWrapper = styled.div<{ disableBorder?: boolean; noSpacing?: boolean }>`
  margin: ${(p) => (p.noSpacing ? '0' : '0.625rem 0')};
  padding: 0 0.375rem;
  font-size: 0.8rem;
  color: ${theme.color.gray300};
  padding-top: 0.4rem;
  border-top: ${(p) => (p.disableBorder ? 'none' : `1px solid ${theme.color.gray200}`)};
`;

export const ErrorMessage = styled.div`
  font-size: 0.85rem;
  color: var(--mdc-theme-error);
  padding-bottom: 0.5rem;
`;

export const ConfirmationMessage = styled.div`
  font-size: 0.85rem;
`;

export const TwoFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TwoFieldsInputWrapper = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
`;

export const TwoFieldsSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

export const DialogFormWrapper = styled.div`
  padding: 0.375rem 0;
`;

export const FormikForm = styled(Form)`
  flex-direction: column;
  min-width: 100%;
  display: flex;
  flex-grow: 1;
`;
