import styled, { css } from 'styled-components'; //macro

import theme from 'app/theme';
import IconButton from 'core/components/IconButton';

export const PopoverWrapper = styled.div<{ $noItems?: boolean }>`
  background: ${theme.color.white};
  z-index: 1;
  /* 4px value follows material design surface component we are using */
  border-radius: 4px;

  overflow-y: auto;
  max-height: inherit;

  box-shadow: ${theme.popoverShadow};

  /* Does not seem to get rerendered properly when using normal styled components prop */
  visibility: visible;
  &[data-reference-hidden] {
    visibility: hidden;
  }

  a {
    color: ${theme.color.secondary};
  }

  ${({ $noItems }) =>
    $noItems &&
    css`
      padding: 0.625rem;
      font-size: 0.875rem;
    `};
`;

export const SmallRippleIconButton = styled(IconButton)`
  /* Hacky way to make ripple smaller, and the button correctly positioned */
  &.mdc-icon-button {
    top: 3px;
    --mdc-ripple-fg-size: 20px !important;
    --mdc-ripple-fg-scale: 1.71429 !important;
    --mdc-ripple-left: 14px !important;
    --mdc-ripple-top: 14px !important;
  }
`;
