import IconButton from '@material/react-icon-button';
import { FC } from 'react';
import styled, { AnyStyledComponent, css, keyframes } from 'styled-components'; //macro

import theme from 'app/theme';
import Button, { ButtonProps } from 'core/components/Button';
import MaterialIcon from 'core/components/MaterialIcon';

export const Header = styled.div`
  align-items: center;
  display: flex;
  padding: 1rem;
`;

export const Wrapper = styled.div<{ style?: any }>`
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1;
`;

export const CloseIcon = styled(MaterialIcon as AnyStyledComponent)`
  font-size: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
` as FC<{ icon: string }>;

export const CloseButton = styled(IconButton as AnyStyledComponent)`
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  margin: -1rem 0rem -1rem auto;
`;

export const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1;
  margin: 0 0 0 1rem;
`;

export const GroupTitle = styled.div`
  justify-content: space-between;
  color: ${theme.color.gray300};
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;

  & > a {
    color: ${theme.color.gray300} !important;
    text-transform: none;
  }
`;

export const ItemSpace = styled.div<{ expanded: boolean }>`
  padding: 0.5rem;
  background-color: ${({ expanded }) => (expanded ? theme.color.gray100 : theme.color.white)};
  transition:
    height 400ms ease-in-out,
    top 400ms ease-in-out;
`;

export const ItemWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 0.25rem 1rem;
  border-radius: 4px;

  cursor: ${({ expanded }) => (expanded ? 'inherit' : 'pointer')};
  background-color: ${theme.color.white};
  transition: background-color 200ms;

  &:hover {
    background-color: ${({ expanded }) => (expanded ? theme.color.white : theme.color.gray)};
  }
`;

export const ReadButton = styled.button`
  pointer-events: all;
  background: var(--mdc-theme-primary);
  border-radius: 100%;
  border: none;
  position: absolute;
  height: 0.75rem;
  cursor: pointer;
  width: 0.75rem;
  right: 1rem;
  padding: 0;
  top: 1rem;
  &:before {
    background: var(--mdc-theme-primary);
    border: 0.5rem solid var(--mdc-theme-primary);
    box-sizing: content-box;
    border-radius: 100%;
    position: absolute;
    margin: -0.5rem;
    opacity: 0.15;
    height: 100%;
    width: 100%;
    content: '';
    right: 0;
    left: 0;
    top: 0;
  }
`;

export const ItemIcon = styled(MaterialIcon as AnyStyledComponent)<{
  color: keyof typeof theme.color;
}>`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: 0.75rem 1rem 0 0;

  background: ${({ color }: { color: keyof typeof theme.color }) => theme.color[color]};
  color: ${theme.color.white};
  border-radius: 100%;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const ItemLoadingIcon = styled(ItemIcon)`
  position: relative;
  &::after {
    border: 3px solid rgba(255, 255, 255, 0.25);
    border-top: 3px solid var(--mdc-theme-primary);
    animation: ${spin} 2s linear infinite;
    box-sizing: border-box;
    margin: -50% 0 0 -50%;
    position: absolute;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    content: '';
    z-index: 1;
    left: 50%;
    top: 50%;
  }
`;

export const ItemContent = styled.div`
  width: calc(100% - 3.5rem);
  font-size: 0.85rem;
  color: ${theme.color.gray400};

  & time {
    font-size: 0.875rem;
    opacity: 0.75;
    display: block;
  }
`;

export const ItemPrimary = styled.div`
  color: ${theme.color.baseFontColor};
  font-size: 1rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemText = styled.div<{ expanded: boolean }>`
  overflow: hidden;

  ${({ expanded }) =>
    expanded
      ? css`
          white-space: normal;
        `
      : css`
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
`;

export const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemButtons = styled.div`
  display: flex;
`;

export const ItemButton = styled(Button as AnyStyledComponent)<ButtonProps>`
  margin: 0.25rem;
`;

export const Logo = styled.div`
  width: 2.5rem;
  svg {
    max-width: 100%;
    height: auto;
  }
`;
